import React, { useState, useCallback, useEffect } from 'react'

import HideImageContext from '../contexts/HideImageContext'

const HideImageProvider = ({ children }) => {
  const [isInvisible, setInvisible] = useState(false)

  const changeVisibility = useCallback(
    (e) => {
      if (e.key === 'Meta') {
        setInvisible(!isInvisible)
      }
    },
    [isInvisible],
  )

  useEffect(() => {
    window.addEventListener('keydown', changeVisibility)
    window.addEventListener('keyup', changeVisibility)

    return () => {
      window.removeEventListener('keydown', changeVisibility)
      window.removeEventListener('keyup', changeVisibility)
    }
  }, [changeVisibility])

  return (
    <HideImageContext.Provider value={{ isInvisible, setInvisible }}>
      {children}
    </HideImageContext.Provider>
  )
}

export default HideImageProvider
