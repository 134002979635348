import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledFooterItem,
  StyledFooterItemHead,
  StyledFooterItemList,
  StyledFooterItemListItem,
  StyledFooterItemLink,
} from './styles'
import { generateKey } from '../../common/utils'

const FooterItem = ({ isInColumns, footerTitle, footerList }) => (
  <StyledFooterItem>
    <StyledFooterItemHead>{footerTitle}</StyledFooterItemHead>
    <StyledFooterItemList isInColumns={isInColumns}>
      {footerList.map(({ title, url, target }, index) => (
        <StyledFooterItemListItem key={generateKey(title, index)}>
          {target ? (
            <StyledFooterItemLink
              as="a"
              href={url}
              rel="noopener noreferrer nofollow"
              {...{ target }}
            >
              {title}
            </StyledFooterItemLink>
          ) : (
            <StyledFooterItemLink
              to={url}
              activeClassName="active"
              partiallyActive
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}
        </StyledFooterItemListItem>
      ))}
    </StyledFooterItemList>
  </StyledFooterItem>
)

export default FooterItem

FooterItem.propTypes = {
  isInColumns: PropTypes.bool,
  footerTitle: PropTypes.string.isRequired,
  footerList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
    }),
  ),
}
