import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react'

import {
  StyledMainNav,
  StyledMainNavList,
  StyledMainNavItem,
  StyledMainNavLink,
  StyledMainNavExtraLink,
  StyledMainNavSubMenu,
} from './styles'
import SubMenu from '../SubMenu/SubMenu'
import NAVIGATION from '../../constants/navigation'
import NAVIGATION_LOGIN from '../../constants/navigation-login'
import { matchMedia } from '../../common/utils'
import media from '../../common/MediaQueries'
import { URL_PATHS } from '../../constants/urlPaths'
import AuthorizationContext from '../../common/authorization/Context'

const MainNav = () => {
  const { isLoggedIn, logout } = useContext(AuthorizationContext)

  const [expandedLink, setExpandedMenu] = useState('')

  const mediaQuery = useMemo(() => matchMedia(`${media.tablet}`), [])

  const handleClick = useCallback(
    (label) => (e) => {
      if (mediaQuery.matches) return
      e.preventDefault()
      setExpandedMenu(expandedLink === label ? '' : label)
    },
    [expandedLink, mediaQuery.matches],
  )

  useEffect(() => {
    const onMatchMediaChange = () => {
      setExpandedMenu('')
    }

    mediaQuery.addListener(onMatchMediaChange)

    return () => {
      mediaQuery.removeListener(onMatchMediaChange)
    }
  }, [mediaQuery])

  return (
    <StyledMainNav>
      {!isLoggedIn && (
        <>
          <StyledMainNavList>
            {NAVIGATION.map(({ label, to, subMenu }) => (
              <StyledMainNavItem
                key={label}
                expandedLink={label === expandedLink}
              >
                <StyledMainNavLink
                  onClick={subMenu && handleClick(label)}
                  to={to}
                  partiallyActive
                  activeClassName="active"
                >
                  {label}
                </StyledMainNavLink>
                {subMenu && (
                  <StyledMainNavSubMenu>
                    <SubMenu items={subMenu} />
                  </StyledMainNavSubMenu>
                )}
              </StyledMainNavItem>
            ))}
          </StyledMainNavList>
          <StyledMainNavExtraLink
            to={URL_PATHS.VIP_LOGIN}
            partiallyActive
            activeClassName="active"
          >
            Vip
          </StyledMainNavExtraLink>
        </>
      )}
      {!!isLoggedIn && (
        <>
          <StyledMainNavExtraLink
            to={URL_PATHS.VIP_WELCOME}
            partiallyActive
            activeClassName="active"
          >
            Vip
          </StyledMainNavExtraLink>
          <StyledMainNavList>
            {NAVIGATION_LOGIN.map(({ label, to, active }) => (
              <StyledMainNavItem
                key={label}
                expandedLink={label === expandedLink}
              >
                <StyledMainNavLink
                  to={to}
                  partiallyActive
                  activeClassName="active"
                  active={active}
                >
                  {label}
                </StyledMainNavLink>
              </StyledMainNavItem>
            ))}
            <StyledMainNavItem>
              <StyledMainNavLink
                to={URL_PATHS.VIP_LOGIN}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  logout()
                }}
                partiallyActive
                activeClassName="active"
                active
              >
                Logout
              </StyledMainNavLink>
            </StyledMainNavItem>
          </StyledMainNavList>
        </>
      )}
    </StyledMainNav>
  )
}

export default MainNav
