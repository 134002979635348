import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'

import GlobalStyles from '../common/GlobalStyles'
import theme from '../themes/theme'
import Cookie from './Cookie/Cookie'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import MenuProvider from '../providers/MenuProvider'
import BaseUrlApiContext from '../contexts/BaseUrlApiContext'
import HideImageProvider from '../providers/HideImageProvider'
import { HEADER_TYPES } from '../constants/headerTypes'
import AuthorizationProvider from '../common/authorization/AuthorizationProvider'

const generateMenuItems = (menus, apiUrl) =>
  menus.map(({ node: { target, label, url } }) => {
    const newUrl = url.replace('/city/', '/models/')

    return {
      target: target || '',
      title: label,
      url: newUrl.replace(apiUrl, ''),
    }
  })

const Layout = ({
  children,
  headerType,
  headerIsSticky,
  headerDarkLogo,
  auth,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        allSettings {
          generalSettingsUrl
        }
        menus {
          edges {
            node {
              slug
              menuItems(first: 100) {
                edges {
                  node {
                    title
                    url
                    target
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const menus = data.wp.menus.edges
  const baseApiUrl = `${data.wp.allSettings.generalSettingsUrl}/wp-admin/admin-ajax.php`
  const apiUrl = data.wp.allSettings.generalSettingsUrl

  const [pages, links, locations] = useMemo(
    () =>
      menus.reduce(
        (result, menu) => {
          const {
            slug,
            menuItems: { edges },
          } = menu.node
          const index = result.findIndex((item) => item.key === slug)
          if (index > -1) {
            result[index].list = generateMenuItems(edges, apiUrl)
          }
          return result
        },
        [
          { key: 'column-1', list: [], title: 'Pages' },
          { key: 'column-2', list: [], title: 'Links' },
          { key: 'column-3', list: [], title: 'Locations' },
        ],
      ),
    [menus, apiUrl],
  )

  useEffect(() => {
    window.oncontextmenu = () => false
    window.onselectstart = () => false
    window.ondragstart = () => false
  }, [])

  return (
    <AuthorizationProvider>
      <ThemeProvider theme={theme}>
        <MenuProvider>
          <BaseUrlApiContext.Provider value={baseApiUrl}>
            <HideImageProvider>
              <GlobalStyles />
              <Cookie />
              <Header
                siteTitle={data.site.siteMetadata.title}
                headerType={headerType}
                isSticky={headerIsSticky}
                darkLogo={headerDarkLogo}
                auth={auth}
              />
              <main>{children}</main>
              <Footer pages={pages} links={links} locations={locations} />
            </HideImageProvider>
          </BaseUrlApiContext.Provider>
        </MenuProvider>
      </ThemeProvider>
    </AuthorizationProvider>
  )
}

Layout.propTypes = {
  headerIsSticky: false,
  headerDarkLogo: false,
  headerType: HEADER_TYPES.default,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerIsSticky: PropTypes.bool,
  headerType: PropTypes.oneOf(Object.keys(HEADER_TYPES)),
  headerDarkLogo: PropTypes.bool,
}

export default Layout
