import React, { useMemo } from 'react'

import { StyledCopyrights } from './styles'
import { Typo7 } from '../Typography/Typography'

const Copyrights = () => {
  const year = useMemo(() => new Date().getFullYear(), [])

  return (
    <StyledCopyrights>
      <Typo7 textTransform="uppercase" opacity="0.5">
        COPYRIGHT © {year} ALL RIGHTS RESERVED
      </Typo7>
    </StyledCopyrights>
  )
}

export default Copyrights
