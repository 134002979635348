import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import { rgba } from 'polished'

import { transition } from '../../common/mixins'
import media, { customMedia } from '../../common/MediaQueries'

const StyledMainNavSubMenu = styled.div`
  position: relative;
  top: auto;
  left: 0;
  right: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  max-height: 0;

  @media ${media.tablet} {
    position: absolute;
    top: 100%;
    left: 50%;
    right: auto;
    padding-top: 1rem;
    transform: translateX(-50%);
    transition: 370ms cubic-bezier(0.7, 0, 0, 1);
  }
`

const StyledMainNav = styled.nav`
  width: 100%;

  @media ${customMedia.maxTablet} {
    margin-top: 6rem;
    margin-bottom: 7rem;
    max-height: calc(100vh - 13rem);
    overflow-y: auto;
  }

  @media ${media.tablet} {
    display: flex;
    flex-direction: row;
  }
`

const StyledMainNavList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${media.tablet} {
    align-items: center;
    flex-direction: row;
  }
`

const StyledMainNavLink = styled(Link)`
  display: flex;
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 1.16px;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.6875rem 1.875rem;
  transition: background-size 0.27s ease-in-out, color 0.27s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.yellow};
  }

  &.active {
    color: ${({ theme }) => theme.colors.yellow};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.yellow};
    `}
    
  @media ${media.tablet} {
    font-size: 0.875rem;
    padding: 0.25rem 0;
    text-transform: uppercase;
    color: currentColor;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: currentColor;
      transform: scaleX(0);
      transform-origin: 0 50%;
      width: 100%;
      height: 0.0625rem;
      bottom: 0;
      left: 0;
      ${transition({ property: 'transform' })}
    }

    &.active::before,
    &:hover::before {
      transform: scaleX(1);
    }

    ${({ active }) =>
      active &&
      css`
        color: ${({ theme }) => theme.colors.yellow};
      `}
  }

  @media ${media.desktop} {
    font-size: 0.9375rem;
  }
`

const StyledMainNavItem = styled.li`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.grey, 0.2)};

  ${({ expandedLink }) =>
    expandedLink &&
    css`
      ${StyledMainNavSubMenu} {
        opacity: 1;
        max-height: 100vh;
        pointer-events: auto;
      }
    `}

  @media ${media.tablet} {
    margin-right: 1.375rem;
    border-bottom: none;

    &:hover {
      ${StyledMainNavLink} {
        color: ${({ theme }) => theme.colors.yellow};
        background-size: 100% 1px;
      }
      ${StyledMainNavSubMenu} {
        opacity: 1;
        max-height: 100vh;
        pointer-events: auto;
      }
    }
  }

  @media ${media.desktop} {
    margin-right: 1.5rem;

    &:last-child{
      margin-right: 1.75rem;
    }
  }
`

const StyledMainNavExtraLink = styled(Link)`
  position: relative;
  display: block;
  color: ${({ theme }) => theme.colors.yellow};
  text-transform: uppercase;
  font-size: 1.25rem;
  letter-spacing: 4px;
  padding: 0.6875rem 1.875rem;
  margin-top: 0.125rem;

  &.active {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  @media ${customMedia.maxTablet} {
    & + ${StyledMainNavList} {
      border-top: 1px solid ${({ theme }) => rgba(theme.colors.grey, 0.2)};
    }
  }

  @media ${media.tablet} {
    width: 3rem;
    text-align: center;
    font-size: 1.125rem;
    padding: 0.25rem 0;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 99%;
      height: 0.0625rem;
      background: currentColor;
      ${transition({ property: 'transform' })}
    }

    &::before {
      top: 0;
      transform: translateY(0.25rem);
    }

    &::after {
      bottom: 0;
      transform: translateY(-0.25rem);
    }

    &:hover {
      &::before,
      &::after {
        transform: translateY(0);
      }
    }

    & + ${StyledMainNavList} {
      margin-left: 1.5rem;
    }
  }
`

export {
  StyledMainNav,
  StyledMainNavList,
  StyledMainNavItem,
  StyledMainNavLink,
  StyledMainNavExtraLink,
  StyledMainNavSubMenu,
}
