import React from 'react'
import PropTypes from 'prop-types'

import { StyledInternalLinkLabel, StyledInternalLink } from './styles'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import { BUTTON_SIZES } from '../../../constants/buttonSizes'

const InternalLink = ({ to, children, submit, decor, ...rest }) => (
  <StyledInternalLink href={to} {...{ decor, submit, to, ...rest }}>
    <StyledInternalLinkLabel>{children}</StyledInternalLinkLabel>
  </StyledInternalLink>
)

export default InternalLink

InternalLink.defaultProps = {
  btnType: BUTTON_TYPES.primary,
  btnSize: BUTTON_SIZES.medium,
  decor: true,
}

InternalLink.propTypes = {
  btnType: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
  btnSize: PropTypes.oneOf(Object.keys(BUTTON_SIZES)),
  decor: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
