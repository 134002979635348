import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

// link styles mixin
const footerLinksStyles = css`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 1.8;

  &:hover {
    opacity: 0.5;
  }
`

const StyledFooterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledFooterItemHead = styled.h3`
  position: relative;
  width: 100%;
  padding-bottom: 0.9375rem;
  margin-bottom: 1.5625rem;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.6px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  &:after {
    content: '';
    position: absolute;
    left: 0.0625rem;
    bottom: 0;
    width: 2rem;
    height: 0.1875rem;
    background-color: ${({ theme }) => theme.colors.yellow};
  }
`

const StyledFooterItemList = styled.ul`
  width: 100%;
  ${({ isInColumns }) =>
    isInColumns &&
    css`
      column-count: 2;
      column-gap: 1.25rem;

      @media ${media.tablet} {
        column-count: 3;
      }

      @media ${media.desktop} {
        column-count: 4;
        column-gap: 6.25rem;
      }
    `};
`

const StyledFooterItemListItem = styled.li`
  padding-bottom: 0.25rem;

  @media ${media.phone} {
    padding-bottom: 0.125rem;
  }
`

const StyledFooterItemLink = styled(Link)`
  display: block;
  ${footerLinksStyles}
  ${transition}

  &.active {
    opacity: 0.5;
  }
`

export {
  StyledFooterItem,
  StyledFooterItemHead,
  StyledFooterItemList,
  StyledFooterItemListItem,
  StyledFooterItemLink,
}
