import React, { useCallback, useMemo, useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { navigate } from 'gatsby'
import _get from 'lodash/get'

import { URL_PATHS } from '../../constants/urlPaths'

import AuthorizationContext from './Context'

// TODO: PUT this into env variables
const API_URL = 'https://api.topcompanions.com'

const isBrowser = () => typeof window !== 'undefined'

const AuthorizationProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() =>
    isBrowser() ? !!localStorage.getItem('token') : false,
  )
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem('token'))
    if (localStorage.getItem('token')) {
      refresh()
    } else {
      setIsChecked(true)
    }
  }, [])

  const onErrorCallback = useCallback((message) => {
    setError(message)
    navigate(URL_PATHS.VIP_LOGIN)
    setIsLoggedIn(false)
    setIsChecked(true)
    localStorage.removeItem('token')
  }, [])

  const refresh = useCallback(async () => {
    try {
      const { data: response } = await axios.get(API_URL, {
        params: {
          rest_route: '/auth/v1/auth/validate',
          jwt: localStorage.getItem('token'),
        },
      })
      if (response.success) {
        checkVIP(false)
      }
    } catch (e) {
      const { data: response } = await axios.post(API_URL, null, {
        params: {
          rest_route: '/auth/v1/auth/refresh',
          JWT: localStorage.getItem('token'),
        },
      })
      if (response.success) {
        refresh()
      } else {
        onErrorCallback(
          'You have beed automatically signed out. Please sign in again.',
        )
      }
    }
  }, [])

  const checkVIP = useCallback(async (shouldRedirect = false) => {
    const token = localStorage.getItem('token')

    if (token) {
      try {
        const response = await axios.get(`${API_URL}/wp-json/topco/v1/vip`, {
          params: { email: jwt_decode(token).email },
        })

        if (_get(response, 'data.user_is_vip')) {
          setIsLoggedIn(true)
          setError('')
          setIsChecked(true)
          if (shouldRedirect) {
            navigate(URL_PATHS.VIP_WELCOME)
          }
        } else {
          onErrorCallback(
            "You don't have a VIP account. Please contact us if you need help or more info.",
          )
        }
      } catch (e) {
        onErrorCallback('Something went wrong. Please try again later.')
      }
    }
  }, [])

  const login = useCallback(async (data) => {
    const { data: response } = await axios.post(API_URL, null, {
      params: {
        rest_route: '/auth/v1/auth',
        username: data.username,
        password: data.password,
      },
    })
    const token = _get(response, 'data.jwt')

    if (token) {
      localStorage.setItem('token', token)
      await checkVIP(true)
    }
    return response
  }, [])

  const logout = useCallback(() => {
    localStorage.removeItem('token')
    setIsLoggedIn(false)
  }, [])

  const value = useMemo(
    () => ({
      isLoggedIn,
      login,
      logout,
      refresh,
      isChecked,
      error,
    }),
    [isLoggedIn, isChecked, error],
  )

  return (
    <AuthorizationContext.Provider value={value}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export default AuthorizationProvider
