import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description, data, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = data?.metaDesc
    ? data?.metaDesc
    : description || site.siteMetadata.description
  const metaKeywords = data?.metaKeywords
  const metaTitle = data?.opengraphTitle ? data?.opengraphTitle : title
  const metaOpenGraphDescription = data?.opengraphDescription
    ? data?.opengraphDescription
    : metaDescription
  const metaOpenGraphType = data?.opengraphType
  const metaTwitter = data?.twitterImage
  const metaTwitterTitle = data?.twitterTitle ? data?.twitterTitle : title
  const metaTwitterDescription = data?.twitterDescription
    ? data?.twitterDescription
    : metaDescription
  const url = data?.opengraphUrl
    ? data.opengraphUrl.replace(
        'api.topcompanions.com',
        `${process.env.GATSBY_URL}/news`,
      )
    : ''

  const image = data?.opengraphImage?.mediaItemUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaOpenGraphDescription,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:type`,
          content: metaOpenGraphType,
        },
        {
          name: `twitter:card`,
          content: metaTwitter,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTwitterTitle,
        },
        {
          name: `twitter:description`,
          content: metaTwitterDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO

export const query = graphql`
  fragment seoFragment on wp_SEO {
    title
    metaDesc
    metaKeywords
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphAuthor
    opengraphDescription
    opengraphImage {
      mediaItemUrl
    }
    opengraphModifiedTime
    opengraphPublishedTime
    opengraphPublisher
    opengraphSiteName
    opengraphTitle
    opengraphType
    opengraphUrl
    twitterDescription
    twitterImage {
      mediaItemUrl
    }
    twitterTitle
  }
`
