import React from 'react'
import PropTypes from 'prop-types'

import { StyledSubMenu, StyledSubMenuItem, StyledSubMenuLink } from './styles'

const SubMenu = ({ items }) => (
  <StyledSubMenu>
    {items.map(({ label, to }) => (
      <StyledSubMenuItem key={label}>
        <StyledSubMenuLink {...{ to }}>{label}</StyledSubMenuLink>
      </StyledSubMenuItem>
    ))}
  </StyledSubMenu>
)

export default SubMenu

SubMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired,
  ),
}
