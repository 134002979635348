import React, { useContext } from 'react'

import { StyledHamburger, StyledHamburgerLine } from './styles'
import MenuContext from '../../contexts/MenuContext'

const Hamburger = () => {
  const { isVisible, handleClick } = useContext(MenuContext)

  return (
    <StyledHamburger
      onClick={handleClick}
      isOpened={isVisible}
      aria-label="Open menu"
      type="button"
    >
      <StyledHamburgerLine />
    </StyledHamburger>
  )
}

export default Hamburger
