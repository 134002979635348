import '../fonts/fonts.css'

export default {
  colors: {
    black: '#000',
    white: '#FFF',
    yellow: '#FCB42F',
    lightYellow: '#FFE0A3',
    lightGrey: '#F6F6F6',
    lightGrey2: '#E5E6EC;',
    lightGrey3: '#D6D6D6',
    lightGrey4: '#FAFAFA',
    grey: '#979797',
    darkGrey: '#111111',
    darkGrey2: '#231F20',
    tundora: '#4A4A4A',
    orange: '#F5A623',
    error: '#FB3D40',
    success: '#81D081',
  },
  breakpoints: ['768px', '1024px', '1050px', '1440px'],
  sizes: {
    inner: '1200px',
    headerMobile: '4.875rem',
    header: '6.25rem',
  },
  fontWeights: {
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
  fontFamilies: {
    georgia: `'Georgia', sans-serif;`,
    oswald: `'Oswald', sans-serif;`,
    annabelle: `'Annabelle', sans-serif;`,
    proximaNova: `'proxima-nova', sans-serif;`,
  },
  textStyles: {
    heading1: {
      fontSize: ['2rem', '4rem', '4.375rem'],
      lineHeight: ['1.4', '1.15'],
      fontFamily: 'annabelle',
    },
    heading2: {
      fontSize: ['2.25rem', '3rem', '3.75rem'],
      lineHeight: ['1.38', '1.25'],
      fontFamily: 'oswald',
      letterSpacing: ['normal', '1.3px'],
    },
    heading3: {
      fontSize: ['1.5rem', '2rem'],
      lineHeight: ['1.5', '1.38'],
      fontFamily: 'oswald',
      letterSpacing: ['0.52px', '1px'],
    },
    heading4: {
      fontSize: ['1.6rem', '1.875rem'],
      lineHeight: '1.5',
      fontFamily: 'oswald',
      letterSpacing: ['0.5px', '0.63px'],
    },
    heading5: {
      fontSize: ['2.25rem'],
      lineHeight: '1.38',
      fontFamily: 'oswald',
    },
    heading6: {
      fontSize: ['2.25rem', '1.875rem'],
      lineHeight: '1.38',
      letterSpacing: ['1px', '0.75px'],
      fontFamily: 'oswald',
    },
    heading7: {
      fontSize: ['1.5rem', '2.25rem'],
      letterSpacing: ['0.4px', '0.9px'],
      fontFamily: 'oswald',
    },
    heading8: {
      fontSize: ['1.75rem', '2.25rem'],
      letterSpacing: ['0.84px', '1px'],
      fontFamily: 'oswald',
    },
    heading9: {
      fontSize: ['1.5rem', '1.875rem'],
      lineHeight: '1.5',
      letterSpacing: ['0.6px', '0.75px'],
      fontFamily: 'oswald',
    },
    body: {
      fontSize: '1rem',
      lineHeight: '1.5625',
      letterSpacing: ['0.58px', '0.28px'],
      opacity: '0.8',
    },
    typo1: {
      fontSize: ['1rem', '1.125rem'],
      lineHeight: '1.6',
      letterSpacing: ['0.28px', '0.32px'],
      opacity: '0.7',
    },
    typo2: {
      fontSize: ['1.125rem', '1.5rem'],
      lineHeight: '1.458',
      letterSpacing: '0.42px',
    },
    typo3: {
      fontSize: '1.5rem',
      lineHeight: '1',
      letterSpacing: '0.6px',
      fontFamily: 'oswald',
    },
    typo4: {
      fontSize: ['1rem', '1.25rem'],
      lineHeight: ['1.6875', '1.6'],
      letterSpacing: ['0.4px', '0.35px'],
    },
    typo5: {
      fontSize: '0.875rem',
      lineHeight: '2',
      letterSpacing: '0.35px',
    },
    typo6: {
      fontSize: ['1rem', '1.25rem', '1.5rem'],
      letterSpacing: ['0.4px', '0.6px'],
    },
    typo7: {
      fontSize: '0.75rem',
      lineHeight: '2.2',
    },
  },
}
