import React from 'react'
import Link from 'gatsby-link'
import CookieConsent from 'react-cookie-consent'

import { URL_PATHS } from '../../constants/urlPaths'

const Cookie = () => (
  <CookieConsent location="bottom" buttonText="I agree" expires={150}>
    We use cookies to improve your experience on this site. Read{' '}
    <Link to={URL_PATHS.PRIVACY_POLICY}>our policy</Link> here.
  </CookieConsent>
)

export default Cookie
