import styled from 'styled-components'
import { space, maxWidth, position } from 'styled-system'

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  max-width: ${({ theme }) => theme.sizes.inner};
  ${space}
  ${maxWidth}
  ${position}
`

export default Inner
