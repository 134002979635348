import styled, { css } from 'styled-components'

import media from './MediaQueries'
import theme from '../themes/theme'

export const transition = ({
  property = 'all',
  duration = '270ms',
  timingFunction = 'ease-in-out',
}) => css`
  transition: ${property} ${duration} ${timingFunction};
`

export const IconWrapper = styled.span`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;

  svg {
    width: ${({ width }) => `${width || 1}em`};
    height: 1em;
    font-size: ${({ fontSize }) => `${fontSize || 1}rem`};
    fill: currentColor;
  }
`

const baseDecorLink = css`
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s;
`

export const decorLink = css`
  ${baseDecorLink}
  background-size: 0% 1px;
  ${transition({ property: 'background-size' })}
`

export const decorHoverLink = css`
  background-size: 100% 1px;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background: none;
    text-decoration: underline;
  }
`

export const decorReverseLink = css`
  ${baseDecorLink}
  background-size: 100% 1px;
  ${transition({ property: 'background-size' })}
`

export const decorHoverReverseLink = css`
  background-size: 0% 1px;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background: none;
  }
`

export const fieldReset = css`
  width: 100%;
  border-radius: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  font-family: ${({ theme }) => theme.fontFamilies.georgia};
  font-size: 0.9375rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::placeholder,
  &::-ms-input-placeholder {
    /* IE11 seems to need the !important flag */
    color: currentColor !important;
    opacity: 0.4 !important;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &::-ms-expand {
    display: none;
  }

  &:-webkit-autofill {
    background: transparent;
    box-shadow: none;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
`

export const date = css`
  font-size: 0.75rem;
  opacity: 0.5;
  line-height: 2;
  letter-spacing: 1.7px;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  text-transform: uppercase;
  margin-bottom: 0.75rem;
`

export const slantLeft = css`
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 82%);

  @media ${media.phone} {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 74%);
  }
`

export const slantRight = css`
  clip-path: polygon(0 0, 100% 0, 100% 82%, 0 100%);

  @media ${media.phone} {
    clip-path: polygon(0 0, 100% 0, 100% 74%, 0 100%);
  }
`

export const slantLeftFallback = ({
  background = `${theme.colors.black}`,
}) => css`
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      background: transparent;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-width: 8rem 0 0 100vw;
      border-color: transparent transparent transparent ${background};

      @media ${media.phone} {
        border-width: 12rem 0 0 100vw;
      }
    }
  }
`

export const slantRightFallback = ({
  background = `${theme.colors.black}`,
}) => css`
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      background: transparent;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-width: 0 0 8rem 100vw;
      border-color: transparent transparent ${background} transparent;

      @media ${media.phone} {
        border-width: 0 0 12rem 100vw;
      }
    }
  }
`
