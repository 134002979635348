import { URL_PATHS } from './urlPaths'

export default [
  {
    label: 'Models',
    to: URL_PATHS.MODELS,
  },
  {
    label: 'Services',
    to: URL_PATHS.SERVICES,
  },
  {
    label: 'Bookings',
    to: URL_PATHS.BOOKINGS,
  },
  {
    label: 'Contact',
    to: URL_PATHS.CONTACT,
  },
]
