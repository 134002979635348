export const URL_PATHS = {
  ABOUT: '/escort-agency',
  MODELS: '/models',
  TRAVEL_ESCORT: '/category/travel-escort',
  CASTING: '/casting-escort-job',
  BOOKINGS: '/bookings',
  NEWS: '/news',
  CONTACT: '/contact',
  CATEGORY: '/category',
  SERVICES: '/services',
  VIP_LOGIN: '/vip-login',
  VIP_WELCOME: '/vip-dashboard',
  BLOG: '/news',
  SITEMAP: '/sitemap',
  GENTLEMENS_GUIDE: '/gentlemens-guide',
  RATES: '/rates',
  TAG: '/tag',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_CONDITIONS: '/terms-conditions',
  EVA: '/models/eva',
  EXCLUSIVE_ARRANGEMENT: '/category/exclusive-arrangement-escort',
}
