import styled from 'styled-components'
import PropTypes from 'prop-types'

import theme from '../../themes/theme'
import DynamicComponent from '../../common/DynamicComponent'

const {
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  heading7,
  heading8,
  heading9,
  body,
  typo1,
  typo2,
  typo3,
  typo4,
  typo5,
  typo6,
  typo7,
} = theme.textStyles

const createTypoComponent = (props) => {
  const TypoComponent = styled(DynamicComponent)`
    strong {
      color: ${({ theme }) => theme.colors.yellow};
    }
  `

  TypoComponent.defaultProps = {
    ...props,
  }

  TypoComponent.propTypes = {
    fontSize: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    lineHeight: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    letterSpacing: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.string,
    opacity: PropTypes.string,
  }

  return TypoComponent
}

export const Heading1 = createTypoComponent(heading1)
export const Heading2 = createTypoComponent(heading2)
export const Heading3 = createTypoComponent(heading3)
export const Heading4 = createTypoComponent(heading4)
export const Heading5 = createTypoComponent(heading5)
export const Heading6 = createTypoComponent(heading6)
export const Heading7 = createTypoComponent(heading7)
export const Heading8 = createTypoComponent(heading8)
export const Heading9 = createTypoComponent(heading9)
export const Body = createTypoComponent(body)
export const BodyDiv = createTypoComponent({ ...body, as: 'div' })
export const Typo1 = createTypoComponent(typo1)
export const Typo2 = createTypoComponent(typo2)
export const Typo3 = createTypoComponent(typo3)
export const Typo4 = createTypoComponent(typo4)
export const Typo5 = createTypoComponent(typo5)
export const Typo6 = createTypoComponent(typo6)
export const Typo7 = createTypoComponent(typo7)
