import styled from 'styled-components'

import media from '../../common/MediaQueries'

const StyledCopyrights = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;

  @media ${media.phone} {
    margin-left: 2rem;
  }
`

export { StyledCopyrights }
