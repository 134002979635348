import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import { LOGO_TYPES } from '../../constants/logoTypes'
import { IconWrapper, transition } from '../../common/mixins'
import media from '../../common/MediaQueries'

const TYPE_MOBILE_MAP = {
  [LOGO_TYPES.default]: '3rem',
  [LOGO_TYPES.compact]: '2.125rem',
}

const TYPE_MAP_TABLET = {
  [LOGO_TYPES.default]: '4rem',
  [LOGO_TYPES.compact]: '3.25rem',
}

const TYPE_MAP = {
  [LOGO_TYPES.default]: '4rem',
  [LOGO_TYPES.compact]: '3.5rem',
}

const StyledLogoIcon = styled(IconWrapper)``

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};

  svg {
    ${transition}
  }

  ${({ dark }) =>
    dark &&
    css`
      color: ${({ theme }) => theme.colors.darkGrey2};
    `}

  ${({ logoType }) =>
    TYPE_MAP[logoType] &&
    css`
      svg {
        font-size: ${TYPE_MOBILE_MAP[logoType]};
      }
    `}


  @media ${media.phone} and ${media.tabletSmall} {
    svg {
      font-size: 2.75rem;
    }
  }

  @media ${media.tablet} {
    ${({ logoType }) =>
      TYPE_MAP[logoType] &&
      css`
        svg {
          font-size: ${TYPE_MAP_TABLET[logoType]};
        }
      `}
  }

  @media ${media.desktop} {
    ${({ logoType }) =>
      TYPE_MAP[logoType] &&
      css`
        svg {
          font-size: ${TYPE_MAP[logoType]};
        }
      `}
  }
`

const StyledLogo = styled(Link)`
  display: flex;
`

const StyledLogoLabel = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

export { StyledLogoWrapper, StyledLogo, StyledLogoIcon, StyledLogoLabel }
