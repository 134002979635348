import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset-advanced'

import { transition, decorReverseLink, decorHoverReverseLink } from './mixins'

const GlobalStyles = createGlobalStyle`
  ${reset}

  html {
    font-size: 16px;
  }

  body {
    font-family: 'Georgia', sans-serif;
    line-height: 1.45;
    color: #000;
    font-weight: 400;
  }

  p, h1, h2, h3, h4, h5, h6, blockquote, pre, ul, ol, li {
    user-select: none;
  }

  iframe,
  img {
    display: block;
    max-width: 100%;
  }

  body.ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModalPortal {
    position: relative;
    z-index: 100;
  }

  /* .uiAnimBottom,
  .uiAnimCustomBottom  {
    visibility: hidden;
  } */

  .CookieConsent {
    flex-wrap: nowrap !important;
    align-items: center !important;
    justify-content: center !important;
    background: ${({ theme }) => theme.colors.black} !important;
    font-family: ${({ theme }) => theme.fontFamilies.georgia};

    > div:first-child {
      flex: unset !important;
      width: auto !important;
    }

    > div:nth-child(2) {
      flex-shrink: 0;
    }

    a {
      ${transition}
      ${decorReverseLink}

      &:hover {
        ${decorHoverReverseLink}
      }
    }

    button  {
      background: ${({ theme }) => theme.colors.yellow} !important;
      border: 1px solid ${({ theme }) => theme.colors.yellow} !important;
      color: ${({ theme }) => theme.colors.black} !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      padding: 0.5rem 2rem !important;
      min-height: 2.5rem;
      font-size: 0.875rem;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      ${transition}
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme.colors.black} !important;
        color: ${({ theme }) => theme.colors.yellow} !important;
      }
    }
  }
`

export default GlobalStyles
