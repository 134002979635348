import styled, { css } from 'styled-components'

import Container from '../Container'
import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

const StyledFooter = styled.footer`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`

const StyledFooterTop = styled(Container)`
  width: 100%;
  padding: 3.125rem 0 0.625rem;

  @media ${media.phone} {
    padding: 5.125rem 0 6.25rem;
  }
`

const StyledFooterBottom = styled.div`
  padding: 1.25rem 0;

  @media ${media.phone} {
    padding: 1.25rem 0 1.5rem;
  }
`

const StyledFooterTopContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.625rem;
`

const StyledFooterTopContainerCol = styled.div`
  display: flex;
  padding: 0.625rem;


  ${({ pages }) =>
    pages &&
    css`
      flex-basis: 50%;
      max-width: 50%;

      @media ${media.phone} {
        flex-basis: 16%;
        max-width: 16%;
      }
    `}

  ${({ links }) =>
    links &&
    css`
      flex-basis: 50%;
      max-width: 50%;

      @media ${media.phone} {
        flex-basis: 30%;
        max-width: 30%;
      }
    `}

  ${({ locations }) =>
    locations &&
    css`
      flex-basis: 100%;
      max-width: 100%;

      @media ${media.phone} {
        flex-basis: 54%;
        max-width: 54%;
      }
    `}
`

const StyledFooterBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${media.phone} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledFooterBottomContainerCol = styled(Container)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  a {
    display: block;
    p {
      ${transition}
    }

    &:hover {
      p {
        opacity: 1;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  @media ${media.phone} {
    flex-direction: row;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

export {
  StyledFooter,
  StyledFooterTop,
  StyledFooterTopContainer,
  StyledFooterTopContainerCol,
  StyledFooterBottom,
  StyledFooterBottomContainer,
  StyledFooterBottomContainerCol,
}
