import React from 'react'

import media from './MediaQueries'
import InternalLink from '../components/Buttons/InternalLink/InternalLink'
import { BUTTON_TYPES } from '../constants/buttonTypes'
import { BG_TYPES } from '../constants/bgTypes'
import { URL_PATHS } from '../constants/urlPaths'
import { NEWSLETTER_SPACINGS } from '../constants/newsletterSpacings'
import { MONTHS } from '../constants/months'

export const fillWithValueLinks = (array, path = '') => {
  const newArray = array
    .filter((model) => !model.node?.acf?.comingSoon)
    .map(({ node: { id, name, title, slug } }) => ({
      id,
      name: name || title,
      slug: slug === 'home' ? `${path}/` : `${path}/${slug}`,
    }))

  return newArray
}

export const generateKey = (el, index) => `${el}_${index}`

export const sortAlphabeticallyBy = (key) => (a, b) =>
  a[key] > b[key] ? 1 : -1

export const matchMedia =
  typeof window !== 'undefined' ? window.matchMedia : () => ({ matches: false })

/**
 * function that builds an internal link from object type and slug
 *
 * @param {Object} linkObj Link object
 */

export const buildInternalLink = (linkObj) => {
  const contentType = linkObj?.target?.contentType
  const slug = linkObj?.target?.slug
  if (contentType && slug) {
    const typeName = contentType.node.name
    switch (typeName) {
      case 'post':
        return `${URL_PATHS.BLOG}/${slug}`
      case 'page':
        return `/${slug}`
      case 'term_cat':
        return `/${slug}`
      case 'term_city':
        return `${URL_PATHS.MODELS}/${slug}`
      case 'model':
        return `${URL_PATHS.MODELS}/${slug}`
      default:
        return `/${slug}`
    }
  } else {
    return null
  }
}

export const buildAction = (
  { internalOrExternal, internal, external, btnType = BUTTON_TYPES.primary },
  index = 0,
  pageType = '',
) => {
  let action = ''

  if (internalOrExternal === 'internal' && internal.title) {
    const { title } = internal
    const url =
      internal.hasOwnProperty('target') && internal.target !== null
        ? buildInternalLink(internal)
        : '#'

    action = (
      <InternalLink
        key={index}
        to={url}
        btnType={
          pageType === 'page_Gentlemensguideacf' ||
          pageType === 'page_Viploginacf'
            ? index === 0
              ? BUTTON_TYPES.secondary
              : BUTTON_TYPES.inverted
            : btnType
        }
      >
        {title}
      </InternalLink>
    )
  } else if (internalOrExternal === 'external') {
    action = (
      <a key={index} href={external.url}>
        {external.title}
      </a>
    )
  }

  return action
}

export const generateModelDetails = (model) => {
  const details = [
    {
      data: model.birthdate || '',
      title: 'Age',
    },
    {
      data: model.height || '',
      title: 'Height',
    },
    {
      data: model.dressSize || '',
      title: 'Dress Size',
    },
    {
      data: model.lingerie || '',
      title: 'Lingerie',
    },
    {
      data: model.eyes || '',
      title: 'Eyes',
    },
    {
      data: model.occupation || '',
      title: 'Occupation',
    },
    {
      data: model.languages || '',
      title: 'Languages',
    },
  ]

  return details
}

export const responsiveImage = (imgMobile, imgDesktop) => {
  const imageMobile = imgMobile?.imageFile?.childImageSharp.fluid

  const imageDesktop = imgDesktop?.imageFile?.childImageSharp.fluid

  const heroImages = imageMobile
    ? [imageMobile, { ...imageDesktop, media: `${media.phone}` }]
    : imageDesktop

  return heroImages
}

export const replaceAtToSpan = (text) => text.replace('@', '<span></span>')

export const bgTheme = (bgColor) => BG_TYPES[bgColor] || BG_TYPES.black

export const formatDate = (date) => {
  const formattedDate = `${
    MONTHS[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`

  return formattedDate
}

export const newsletterSpacings = (spacings) =>
  NEWSLETTER_SPACINGS[spacings] || ''

export const replaceBadUrls = (text) => {
  const correctBase = `https://www.${process.env.GATSBY_URL}`

  return text
    .replace(/http:\/\/www\.topcompanions\.com/gi, correctBase)
    .replace(/http:\/\/topcompanions\.com/gi, correctBase)
    .replace(/https:\/\/topcompanions\.com/gi, correctBase)
}

export const changeLinksPath = (text, newsLinks) => {
  if (!text) {
    return ''
  }

  const newText = text
    .replace(/tpc2.netlify.app/gi, process.env.GATSBY_URL)
    .replace(/api.topcompanions.com/gi, process.env.GATSBY_URL)
    .replace(/topco.aazzxx.com/gi, process.env.GATSBY_URL)
    .replace(/localhost/gi, `www.${process.env.GATSBY_URL}`)
    .replace(/\/term_city/gi, URL_PATHS.MODELS)
    .replace(/\/term_cat/gi, URL_PATHS.CATEGORY)
    .replace(/\/city/gi, URL_PATHS.MODELS)

  const textWithCorrectUrls = replaceBadUrls(newText)
  const textWithCorrectNewsLinks = changeNewsLinks(
    textWithCorrectUrls,
    newsLinks,
  )

  return textWithCorrectNewsLinks
}

const changeNewsLinks = (text, newsLinks) => {
  if (!newsLinks || newsLinks.length === 0) {
    return text
  }

  const correctBase = `https://www.${process.env.GATSBY_URL}`

  return newsLinks.reduce((result, item) => {
    const regex = new RegExp(`${correctBase}/${item}`, 'gim')

    // We slice to get rid of backslash on front
    return result.replace(regex, `${correctBase}${URL_PATHS.NEWS}/${item}`)
  }, text)
}

export const formatCmsContent = (text, newsLinks) => {
  const textWithChangedLinks = changeLinksPath(text, newsLinks)

  const regex = /.*?(\.|\?)(?=\s[A-Z])/
  const firstSentence = `${regex.exec(textWithChangedLinks)[0]}`

  const restContent = textWithChangedLinks.replace(firstSentence, '')

  const newContent = `${firstSentence}</p>`.concat(`<p>${restContent}`)

  return newContent
}

export const makeInitialValues = (fields) =>
  fields.reduce((result, { initialValue, name, fields }) => {
    if (fields) {
      return { ...result, ...makeInitialValues(fields) }
    }

    if (initialValue !== undefined) {
      return { ...result, [name]: initialValue }
    }

    return result
  }, {})

export const generateHero = ({ heroFields } = {}) => {
  if (!heroFields) {
    return {}
  }

  const {
    slantDirection,
    bgGradient,
    heroHeight,
    bgMobile,
    bgDesktop,
    boxBackgroundOpacity,
    boxBackgroundColor,
    backgroundMobile,
    backgroundDesktop,
  } = heroFields
  const hasBgGradient = bgGradient && bgGradient !== 'none'
  const isHeroHigh = heroHeight === 'long'
  const heroBgColorMobile = bgTheme(bgMobile)
  const heroBgColorDesktop = bgTheme(bgDesktop)
  const heroColorMobile = heroBgColorMobile === 'black' ? 'white' : 'black'
  const heroColorDesktop = heroBgColorDesktop === 'black' ? 'white' : 'black'
  const infoBoxOpacity = boxBackgroundOpacity / 100
  const infoBoxBg = boxBackgroundColor
  const heroImages = responsiveImage(backgroundMobile, backgroundDesktop)

  return {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  }
}
