import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import {
  buttonBase,
  primary,
  secondary,
  inverted,
  dark,
  light,
  simple,
  small,
  medium,
} from '../styles'
import { BUTTON_SIZES } from '../../../constants/buttonSizes'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'

const SIZE_MAP = {
  [BUTTON_SIZES.small]: small,
  [BUTTON_SIZES.medium]: medium,
}

const TYPE_MAP = {
  [BUTTON_TYPES.primary]: primary,
  [BUTTON_TYPES.secondary]: secondary,
  [BUTTON_TYPES.inverted]: inverted,
  [BUTTON_TYPES.dark]: dark,
  [BUTTON_TYPES.light]: light,
  [BUTTON_TYPES.simple]: simple,
}

const StyledInternalLinkLabel = styled.span``

const StyledInternalLink = styled(Link)`
  ${buttonBase}

  ${({ btnSize }) =>
    SIZE_MAP[btnSize] &&
    css`
      ${SIZE_MAP[btnSize]}
    `}

  ${({ btnType }) =>
    TYPE_MAP[btnType] &&
    css`
      ${TYPE_MAP[btnType]}
    `}
`

export { StyledInternalLinkLabel, StyledInternalLink }
