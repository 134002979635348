import styled from 'styled-components'
import {
  space,
  textAlign,
  color,
  position,
  overflow,
  display,
  alignItems,
  flexShrink,
  justifyContent,
  flexDirection,
  maxWidth,
  minHeight,
} from 'styled-system'

const Container = styled.div`
  ${space}
  ${textAlign}
  ${color}
  ${position}
  ${overflow}
  ${display}
  ${alignItems}
  ${flexShrink}
  ${justifyContent}
  ${flexDirection}
  ${maxWidth}
  ${minHeight}
`

export default Container
