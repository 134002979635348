import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import Inner from '../Inner'
import media, { customMedia } from '../../common/MediaQueries'
import { HEADER_TYPES } from '../../constants/headerTypes'
import { StyledSubMenu, StyledSubMenuItem } from '../SubMenu/styles'
import { StyledLogoWrapper } from '../Logo/styles'
import { transition } from '../../common/mixins'

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transform: translateZ(0);
  color: ${({ theme }) => theme.colors.white};
  ${transition}
    
  ${({ headerType }) =>
    headerType === HEADER_TYPES.sticky &&
    css`
      position: fixed;
      ${({ isSticky }) =>
        isSticky &&
        css`
          background: ${({ theme }) => theme.colors.black};
        `}
    `}

  ${({ headerType }) =>
    headerType === HEADER_TYPES.alt &&
    css`
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};

      ${StyledSubMenu} {
        color: ${({ theme }) => theme.colors.white};
      }
    `}

    ${({ isOpened }) =>
      isOpened &&
      css`
        position: fixed;
        ${StyledLogoWrapper} {
          color: ${({ theme }) => theme.colors.white};
        }
      `}

    @media ${media.tablet} {
      ${({ isOpened }) =>
        isOpened &&
        css`
          ${StyledLogoWrapper} {
            color: currentColor;
          }
        `}

      ${({ headerType }) =>
        headerType === HEADER_TYPES.default &&
        css`
          position: absolute;

          ${StyledSubMenu} {
            background: ${({ theme }) => rgba(theme.colors.black, 0.85)};
            border: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.5)};
            color: ${({ theme }) => theme.colors.white};
          }
        `}

      ${({ headerType }) =>
        headerType === HEADER_TYPES.sticky &&
        css`
          ${({ isSticky }) =>
            isSticky &&
            css`
              ${StyledSubMenu} {
                background: ${({ theme }) => rgba(theme.colors.black, 0.85)};
                border: 1px solid
                  ${({ theme }) => rgba(theme.colors.white, 0.5)};
                color: ${({ theme }) => theme.colors.white};
              }
            `}
        `}

      ${({ headerType }) =>
        headerType === HEADER_TYPES.alt &&
        css`
          position: relative;

          ${StyledSubMenu} {
            background: ${({ theme }) => rgba(theme.colors.white, 0.95)};
            border: 1px solid ${({ theme }) => rgba(theme.colors.black, 0.3)};
            color: ${({ theme }) => theme.colors.black};
          }

          ${StyledSubMenuItem} {
            &:not(:last-child) {
              border-bottom-color: ${({ theme }) =>
                rgba(theme.colors.black, 0.2)};
            }
          }
        `}
    }

`

const StyledHeaderInner = styled(Inner)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  height: ${({ theme }) => theme.sizes.headerMobile};

  @media ${media.tablet} {
    height: ${({ theme }) => theme.sizes.header};
  }
`

const StyledHeaderLogo = styled.div`
  position: relative;
  z-index: 100;
  margin-left: -0.5rem;

  @media ${media.tablet} {
    margin-left: 0;
  }
`

const StyledHeaderMain = styled.div`
  display: flex;
  align-items: center;

  @media ${media.tablet} {
    margin-left: 2rem;
  }
`

const StyledHeaderNav = styled.div`
  display: flex;
  transition: 500ms cubic-bezier(0.19, 1, 0.22, 1) 170ms;
  z-index: 2;

  @media ${customMedia.maxTablet} {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transform: translateX(0);
    background: ${({ theme }) => theme.colors.black};

    ${({ isOpened }) =>
      isOpened &&
      css`
        transform: translateX(100vw);
        visibility: visible;
        opacity: 1;
      `}
  }

  @media ${media.tablet} {
    transition: none;
    justify-content: center;
  }
`

export {
  StyledHeader,
  StyledHeaderInner,
  StyledHeaderLogo,
  StyledHeaderMain,
  StyledHeaderNav,
}
