import React, { useState, useCallback } from 'react'

import MenuContext from '../contexts/MenuContext'

const MenuProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClick = useCallback(() => {
    setIsVisible(!isVisible)
  }, [isVisible])

  return (
    <MenuContext.Provider value={{ isVisible, setIsVisible, handleClick }}>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuProvider
