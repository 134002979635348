import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  StyledHeader,
  StyledHeaderInner,
  StyledHeaderLogo,
  StyledHeaderMain,
  StyledHeaderNav,
} from './styles'
import Logo from '../Logo/Logo'
import MainNav from '../MainNav/MainNav'
import Hamburger from '../Hamburger/Hamburger'
import { LOGO_TYPES } from '../../constants/logoTypes'
import MenuContext from '../../contexts/MenuContext'
import { HEADER_TYPES } from '../../constants/headerTypes'

const Header = ({ headerType, darkLogo, isSticky }) => {
  const { isVisible } = useContext(MenuContext)

  return (
    <StyledHeader
      headerType={headerType}
      isSticky={isSticky}
      isOpened={isVisible}
    >
      <StyledHeaderInner>
        <StyledHeaderLogo>
          <Logo dark={darkLogo} logoType={LOGO_TYPES.compact} />
        </StyledHeaderLogo>
        <StyledHeaderMain>
          <StyledHeaderNav isOpened={isVisible}>
            <MainNav />
          </StyledHeaderNav>
          <Hamburger />
        </StyledHeaderMain>
      </StyledHeaderInner>
    </StyledHeader>
  )
}

Header.defaultProps = {
  headerType: HEADER_TYPES.default,
  darkLogo: false,
  isSticky: false,
}

Header.propTypes = {
  headerType: PropTypes.oneOf(Object.keys(HEADER_TYPES)),
  darkLogo: PropTypes.bool,
  isSticky: PropTypes.bool,
}

export default Header
