import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledLogoWrapper,
  StyledLogo,
  StyledLogoIcon,
  StyledLogoLabel,
} from './styles'
import { LOGO_TYPES } from '../../constants/logoTypes'

const Logo = ({ dark, logoType }) => {
  const renderLogo = () => {
    switch (logoType) {
      case LOGO_TYPES.compact:
        return (
          <StyledLogoIcon width="6.2615">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2040 325.8">
              <g>
                <path
                  fill="currentColor"
                  d="M794.8,300.5c0,1-0.1,2.6-0.2,5H794c-0.2,0-0.8,0-1.7-0.1c-0.9-0.1-1.9-0.1-3-0.1l-23.9-0.2c-0.7,0-1-0.2-1-0.5
		c0-0.3,0.5-0.4,1.6-0.5c1.5-0.1,2.3-0.2,2.6-0.5c0.3-0.2,0.4-1,0.4-2.2V269c0-1.4-0.1-2.2-0.4-2.5c-0.3-0.2-1.3-0.3-3.1-0.3
		c-0.7,0-1.1-0.2-1.1-0.6c0-0.3,0.2-0.5,0.7-0.5h0.5c1.3,0.1,3.8,0.2,7.5,0.2c0.3,0,1,0,2-0.1c1-0.1,1.8-0.1,2.5-0.1h0.6
		c0.7,0,1,0.2,1,0.6c0,0.2-0.1,0.4-0.3,0.4c-0.2,0.1-0.9,0.1-2,0.1c-1.1,0-1.8,0.2-2,0.5c-0.2,0.3-0.3,1.3-0.3,3v34
		c1,0,2.5,0.1,4.3,0.1c4.5,0,7.7-0.2,9.5-0.7c1.8-0.5,3.1-1.3,3.7-2.6c0.7-1.3,1-3,1.2-5.1c0-0.5,0.3-0.7,0.7-0.7
		c0.5,0,0.7,0.3,0.7,0.9v1.4c0,0.1,0,0.2,0,0.5c0,0.3,0,0.5,0,0.8V300.5z"
                />
                <path
                  fill="currentColor"
                  d="M851.3,265.6c0,0.3-0.3,0.5-1,0.5c-0.1,0-0.5,0-1.1,0c-0.2,0-0.5,0-0.8,0c-1,0-1.5,0.2-1.7,0.7c-0.1,0.3-0.1,0.7-0.1,1.3
		v21.6c0,5.6-1,9.7-3.1,12.2c-2.1,2.5-5.5,3.8-10.1,3.8c-5.6,0-9.5-1.6-11.8-4.7c-1.5-2.1-2.2-5.6-2.2-10.4v-23.1
		c-0.1-0.7-0.6-1.1-1.4-1.3c-0.2,0-1-0.1-2.2-0.1c-0.7-0.1-1-0.3-1-0.6c0-0.3,0.3-0.5,0.8-0.5h1c0.2,0,0.6,0,1.3,0
		c0.6,0,1.2,0,1.7,0h8.2c0.2,0,0.4,0,0.7,0c0.7,0,1,0.2,1,0.6c0,0.3-0.1,0.4-0.3,0.5c-0.2,0-1,0.1-2.3,0.1c-0.5,0-0.9,0-1.1,0.1
		c-0.4,0.2-0.6,0.4-0.6,0.8c0,0.2-0.1,1.2-0.1,3.3v20.6c0,5.1,0.8,8.5,2.4,10.3c1.6,1.8,4,2.8,7.2,2.8c5.8,0,9.1-3.3,9.9-9.9
		c0.1-1,0.2-5.2,0.2-12.6v-3.3v-4.6v-4c0-0.4,0-0.8,0-1.3c0-0.5,0-0.7,0-0.6c0-0.8-0.4-1.4-1.2-1.6c-0.3-0.1-1.1-0.1-2.4-0.1
		c-0.8,0-1.1-0.2-1.1-0.6c0-0.3,0.3-0.5,0.8-0.5c0.3,0,0.6,0,0.8,0c0.5,0,2.1,0,5,0c0.4,0,0.9,0,1.4,0c0.5,0,0.9,0,1.1,0h0.9
		C850.8,265.1,851.3,265.3,851.3,265.6z"
                />
                <path
                  fill="currentColor"
                  d="M906.9,304.6c0,0.3-0.5,0.5-1.6,0.5c-1.3,0-2.6,0-4,0c-1.4,0-2.7,0-4,0c-1.7,0-3.6,0-5.9,0c-1.1,0-1.9,0-2.5,0
		c-1.1,0-1.7-0.2-1.7-0.5c0-0.3,0.4-0.5,1.3-0.4h2.6c0.9,0,1.5,0,1.7-0.1c0.6-0.1,0.9-0.5,0.9-1.1c0-0.6-0.5-1.8-1.5-3.8l-6.1-12.5
		l-7.2,12.3c-1.3,2.2-1.9,3.5-1.9,3.9c0,0.7,0.6,1.1,1.9,1.2c-0.1,0,0.8,0,2.6,0c0.7,0,1,0.2,1,0.5c0,0.3-0.3,0.5-0.9,0.5h-6.5h-4.9
		c-0.6,0-0.9-0.2-0.9-0.5c0-0.3,0.5-0.6,1.5-0.7c0.7-0.1,1.4-0.2,2.1-0.2c0.6-0.2,1.1-0.4,1.4-0.7c0.7-0.7,3.1-4.4,7.1-11.3
		c1.5-2.5,2.8-4.7,3.9-6.6l-6.5-12.3c-1.4-2.5-2.3-4.2-2.8-4.9c-0.5-0.7-1.1-1.2-1.7-1.5c-0.6-0.1-1.8-0.2-3.6-0.2
		c-0.5,0-0.7-0.2-0.7-0.5c0-0.4,0.3-0.6,0.9-0.6c0.8,0,2.6,0,5.2,0.1c1.3,0,2.2,0.1,2.7,0.1c4.5,0,7-0.1,7.4-0.2h0.8
		c0.8,0,1.2,0.2,1.2,0.5c0,0.4-0.7,0.6-2,0.6c-2.5,0-3.8,0.3-3.8,0.9c0,0.7,2.2,5.2,6.5,13.5c1.6-2.3,3.3-5.1,5.1-8.2
		c1.9-3.2,2.8-5,2.8-5.3c0-0.6-1.1-0.9-3.2-0.9c-1,0-1.6-0.2-1.6-0.5c0-0.4,0.4-0.6,1.1-0.6c0.5,0,1.2,0,2.2,0.1
		c1,0.1,1.7,0.1,2.2,0.1c0.2,0,1.1,0,2.7-0.1c1.6-0.1,2.5-0.1,2.8-0.1h0.5c0.7,0,1,0.2,1,0.5c0,0.3-0.2,0.5-0.7,0.5
		c-0.1,0-0.7,0-1.8,0.1c-1.3,0-2.2,0.3-2.7,0.7s-1.3,1.7-2.4,3.7c-1.5,2.7-3.7,6.3-6.6,10.7l-0.6,1c0.3,0.5,2.9,5.5,7.8,15.1
		c1.7,3.3,2.9,5.3,3.6,5.9c0.7,0.6,2.2,0.9,4.3,0.9C906.4,304,906.9,304.2,906.9,304.6z"
                />
                <path
                  fill="currentColor"
                  d="M961.7,265.6c0,0.3-0.3,0.5-1,0.5c-0.1,0-0.5,0-1.1,0c-0.2,0-0.5,0-0.8,0c-1,0-1.5,0.2-1.7,0.7c-0.1,0.3-0.1,0.7-0.1,1.3
		v21.6c0,5.6-1,9.7-3.1,12.2c-2.1,2.5-5.5,3.8-10.1,3.8c-5.6,0-9.5-1.6-11.8-4.7c-1.5-2.1-2.2-5.6-2.2-10.4v-23.1
		c-0.1-0.7-0.6-1.1-1.4-1.3c-0.2,0-1-0.1-2.2-0.1c-0.7-0.1-1-0.3-1-0.6c0-0.3,0.3-0.5,0.8-0.5h1c0.2,0,0.6,0,1.3,0
		c0.6,0,1.2,0,1.7,0h8.2c0.2,0,0.4,0,0.7,0c0.7,0,1,0.2,1,0.6c0,0.3-0.1,0.4-0.3,0.5c-0.2,0-1,0.1-2.3,0.1c-0.5,0-0.9,0-1.1,0.1
		c-0.4,0.2-0.6,0.4-0.6,0.8c0,0.2-0.1,1.2-0.1,3.3v20.6c0,5.1,0.8,8.5,2.4,10.3c1.6,1.8,4,2.8,7.2,2.8c5.8,0,9.1-3.3,9.9-9.9
		c0.1-1,0.2-5.2,0.2-12.6v-3.3v-4.6v-4c0-0.4,0-0.8,0-1.3c0-0.5,0-0.7,0-0.6c0-0.8-0.4-1.4-1.2-1.6c-0.3-0.1-1.1-0.1-2.4-0.1
		c-0.8,0-1.1-0.2-1.1-0.6c0-0.3,0.3-0.5,0.8-0.5c0.3,0,0.6,0,0.8,0c0.5,0,2.1,0,5,0c0.4,0,0.9,0,1.4,0c0.5,0,0.9,0,1.1,0h0.9
		C961.2,265.1,961.7,265.3,961.7,265.6z"
                />
                <path
                  fill="currentColor"
                  d="M1014.4,303.4c0,1.6-1.3,2.4-4,2.4c-3,0-5.3-1.2-7-3.7c-0.7-1.1-1.2-2.1-1.4-3.1c-0.2-1-0.5-3.5-0.9-7.6
		c-0.2-2.5-1-4.2-2.4-5.2c-1.3-1-3.5-1.6-6.6-1.6v18.1c0,0.8,0.6,1.3,1.8,1.4c0.3,0,1.2,0.1,2.7,0.1c0.5,0,0.7,0.2,0.7,0.5
		c0,0.3-0.3,0.5-1,0.5h-0.7c-0.3,0-2,0-5,0c-0.6,0-1.6,0-2.9,0c-1.3,0-2.2,0-2.7,0h-2.2c-0.8,0-1.3-0.2-1.3-0.5
		c0-0.3,0.6-0.4,1.7-0.5c1.8,0,2.8-0.3,2.9-0.7c0.2-0.2,0.2-0.9,0.2-2.2v-31.8c0-1.5-0.2-2.5-0.5-2.8c-0.3-0.3-1.3-0.5-3-0.5
		c-1,0-1.4-0.2-1.4-0.5c0-0.4,0.3-0.6,0.9-0.6c0.1,0,0.3,0,0.5,0.1l7.7,0.2c0.8,0,1.8,0,3.2-0.1c1.5-0.1,2.6-0.2,3.2-0.2
		c3.6,0,6.4,0.8,8.5,2.4c2.1,1.6,3.1,3.8,3.1,6.5c0,2.7-1.1,4.9-3.2,6.7c-2.2,1.7-5.1,2.8-8.8,3.1c-0.3,0-0.5,0.1-0.6,0.2
		c0,0.2,0.2,0.2,0.6,0.2c4,0.7,6.8,2.1,8.3,4.2c1.5,2.1,2.5,6,3,11.6c0.3,3.2,1.4,4.7,3.2,4.7c0.6,0,0.9-0.1,0.9-0.3
		c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.3-0.8-0.3-1c0-0.8,0.4-1.2,1.3-1.2C1014,301.9,1014.4,302.4,1014.4,303.4z M1002.5,274.5
		c0-5.5-2.4-8.2-7.1-8.2c-1.3,0-2.1,0.2-2.6,0.5c-0.5,0.3-0.7,1-0.7,1.8v14.6c3.7,0,6.3-0.6,8-2
		C1001.7,279.9,1002.5,277.6,1002.5,274.5z"
                />
                <path
                  fill="currentColor"
                  d="M1067.8,265.5c0,0.3-0.3,0.4-1,0.4c-2.1,0-3.4,0.4-3.9,1.1c-0.5,0.4-2.1,3.7-5,9.9c-1.3,2.7-2.5,5.1-3.6,7.2
		c-0.8,1.4-1.2,2.4-1.3,2.9c-0.1,0.5-0.2,1.6-0.2,3.4v10.9c0,1.3,0.2,2,0.5,2.3c0.3,0.2,1.5,0.4,3.4,0.5c1,0.1,1.6,0.3,1.6,0.5
		c0,0.3-0.3,0.4-0.8,0.4h-0.7l-8.1-0.2c-2.3,0-4.1,0-5.6,0.1c-0.2,0-0.5,0.1-0.8,0.1c-0.5,0-0.8-0.2-0.8-0.5c0-0.4,0.4-0.6,1.3-0.6
		c2.1-0.1,3.4-0.3,3.7-0.6c0.4-0.3,0.6-1.2,0.6-2.8v-12.1c-0.4-0.8-1.5-3.1-3.3-6.7l-5.8-11.4c-0.6-1.1-1.3-2.4-2.1-3.7
		c-0.4-0.4-1.3-0.6-2.7-0.6c-1.4,0-2.1-0.2-2.1-0.6c0-0.3,0.3-0.5,0.9-0.5c0.8,0,2.4,0,4.8,0.1c1.4,0,2.4,0.1,3,0.1l6.7-0.1
		c0.3-0.1,0.6-0.1,0.9-0.1c0.7,0,1,0.2,1,0.6c0,0.3-0.7,0.5-2,0.5c-2.4,0-3.6,0.4-3.6,1.3c0,0.7,1.2,3.3,3.6,7.9l5.3,10
		c1-1.7,2.1-3.9,3.3-6.4l3.4-6.9c1.2-2.5,1.9-4.1,1.9-4.9c0-0.5-0.3-0.8-1-0.9c-1.3-0.2-2.2-0.3-2.7-0.2c-0.8,0-1.3-0.2-1.3-0.6
		c0-0.3,0.4-0.5,1.1-0.5h3.3h1.9C1065.8,264.8,1067.8,265,1067.8,265.5z"
                />
                <path
                  fill="currentColor"
                  d="M1146.2,295.8c0,0.2,0,1.1-0.1,2.8c-0.1,1.7-0.1,3.4-0.1,5v1.9c-3.8-0.2-11.2-0.3-22.4-0.3c-1.3,0-2.7,0-4,0
		c-1.3,0-2.2,0-2.7,0h-0.6c-0.7,0-1.1-0.2-1.1-0.5c0-0.2,0.1-0.3,0.3-0.4c0.1,0,0.8-0.1,2.2-0.2c1-0.1,1.6-0.3,1.8-0.6
		c0.2-0.3,0.3-1.3,0.3-2.7v-32.5c0-1.1-0.2-1.8-0.5-2c-0.3-0.2-1.4-0.3-3.1-0.3c-0.6,0-0.9-0.2-0.9-0.5c0-0.3,0.4-0.5,1.3-0.5h9.9
		c0.3,0,2.5,0,6.5,0s6.7,0,8.1,0c1.2,0,2.2-0.1,3.2-0.2c0.1,1.6,0.2,3.9,0.5,6.9l0.2,2.5c0,0.2,0.1,0.4,0.1,0.7
		c0,0.4-0.2,0.6-0.5,0.6c-0.4,0-0.7-0.5-0.9-1.5c-0.7-3.5-1.9-5.7-3.6-6.6c-1.2-0.7-4.5-1-9.7-1h-4.7v16.9h1.4c4.3,0,6.9-0.3,7.9-1
		c0.9-0.7,1.6-2.6,1.9-5.7c0.1-0.8,0.3-1.2,0.6-1.2c0.4,0,0.6,0.3,0.6,1c0,0.7,0,1.7-0.1,3.1c-0.1,1.3-0.1,2.4-0.1,3.1
		c0,4.1,0.1,6.9,0.2,8.5c0,0.4,0,0.7,0,1c0,0.5-0.2,0.7-0.6,0.7c-0.3,0-0.4-0.2-0.5-0.5c-0.1-0.3-0.3-1.4-0.5-3.2
		c-0.3-2.3-1.2-3.7-2.8-4.2c-0.7-0.2-2.5-0.3-5.4-0.3h-2.6v19.3c8.4,0,13.4-0.2,14.9-0.5c2.5-0.6,4-3,4.3-7.3
		c0.1-0.9,0.3-1.4,0.7-1.4C1146.1,294.6,1146.2,295,1146.2,295.8z"
                />
                <path
                  fill="currentColor"
                  d="M1192.4,293.8c0,3.3-1.3,6.1-3.8,8.4c-2.5,2.3-5.5,3.5-9.1,3.5c-2.1,0-4.5-0.6-7.4-1.9c-0.8-0.3-1.3-0.5-1.7-0.5
		c-0.5,0-0.7,0.5-0.9,1.6c0,0.5-0.3,0.8-0.7,0.8c-0.4,0-0.6-0.2-0.6-0.7c0-0.3,0-0.5,0.1-0.7c0.3-2.3,0.5-4.5,0.5-6.5
		c0-0.4,0-1.5-0.1-3.2c0-0.2-0.1-0.5-0.1-0.9c0-0.6,0.2-0.9,0.7-0.9c0.4,0,0.7,0.5,0.8,1.4c1,6.9,4.2,10.4,9.7,10.4
		c2.3,0,4.2-0.7,5.4-2c1.3-1.3,1.9-3.2,1.9-5.6c0-2.1-0.6-4-1.9-5.5c-1.2-1.5-3.4-3-6.4-4.5c-3.6-1.8-6.2-3.6-7.6-5.2
		c-1.4-1.7-2.1-3.8-2.1-6.3c0-3.2,1-5.8,3.1-7.9c2.1-2.1,4.7-3.1,7.8-3.1c1.3,0,3.1,0.4,5.4,1.3c0.8,0.3,1.4,0.5,1.8,0.5
		c0.4,0,0.7-0.1,0.8-0.2c0.1-0.2,0.3-0.6,0.4-1.2c0.1-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.2,0.6,0.7c0,0.3,0,0.5-0.1,0.7
		c-0.1,0.9-0.2,2-0.2,3.3c0,1.8,0.1,3.3,0.3,4.4l0.1,0.9c0,0.4-0.1,0.6-0.5,0.6c-0.4,0-0.7-0.4-0.9-1.3c-0.4-2.3-1.4-4.2-3-5.7
		c-1.6-1.5-3.3-2.3-5.3-2.3c-1.9,0-3.4,0.6-4.6,1.8c-1.1,1.2-1.7,2.9-1.7,5c0,2.5,0.8,4.4,2.5,5.9c0.9,0.8,2.9,2,6,3.6
		c3.6,1.8,6.2,3.6,7.8,5.5C1191.7,289.3,1192.4,291.5,1192.4,293.8z"
                />
                <path
                  fill="currentColor"
                  d="M1247.2,292.2c0,0,0,0.5-0.1,1.6c-0.2,2.2-0.2,4.3-0.2,6.5c0,0.3,0,0.8,0.1,1.5c0.1,0.7,0.1,1.6,0.1,2.6v0.5
		c0,0.5-0.2,0.7-0.5,0.7c-0.3,0-0.6-0.3-0.8-0.9c-0.5-1.3-1.1-2.2-1.6-2.5c-2.8,2.4-6.1,3.6-10,3.6c-5.6,0-10.3-2-14-5.9
		c-3.7-3.9-5.6-8.9-5.6-14.9c0-6,1.7-11,5.1-14.8c3.4-3.8,7.8-5.7,13.2-5.7c2,0,3.5,0.2,4.7,0.5c1.2,0.3,2.6,1,4.3,2
		c0.8,0.5,1.5,0.7,1.9,0.7c0.3,0,0.7-0.3,1-0.9c0.5-1.1,0.9-1.6,1.2-1.6s0.5,0.2,0.5,0.5c0,0.2,0,0.4-0.1,0.7
		c-0.4,1.8-0.6,5.3-0.6,10.4c0,0.7-0.1,1.2-0.2,1.3c-0.1,0.1-0.3,0.2-0.5,0.2c-0.4,0-0.6-0.4-0.7-1.1c-0.5-3.1-1.2-5.4-2.1-6.9
		c-1.9-3.1-4.8-4.6-8.9-4.6c-3.1,0-5.6,1-7.4,3.1c-3.3,3.7-4.9,9.2-4.9,16.4c0,6.4,1.3,11.4,4,14.9c2.3,3.1,5.5,4.6,9.3,4.6
		c2,0,3.8-0.5,5.5-1.5c1.7-1,2.9-2.4,3.8-4.1c0.9-1.7,1.6-3.8,2.1-6.3c0.2-0.9,0.4-1.4,0.7-1.4
		C1247,291.3,1247.2,291.6,1247.2,292.2z"
                />
                <path
                  fill="currentColor"
                  d="M1306.8,285.3c0,5.7-1.7,10.6-5.2,14.5c-3.4,4-7.7,5.9-12.6,5.9c-5.3,0-9.8-2-13.4-6c-3.6-4-5.4-9-5.4-14.8
		c0-6,1.7-10.9,5.2-14.7c3.5-3.8,7.9-5.7,13.3-5.7c5.1,0,9.4,2,12.8,6C1305.1,274.5,1306.8,279.4,1306.8,285.3z M1300.4,285.6
		c0-7.1-1.4-12.5-4.3-16.5c-1.8-2.5-4.3-3.7-7.5-3.7c-3.1,0-5.5,0.9-7.1,2.8c-3.1,3.5-4.7,9.1-4.7,16.9c0,6.2,1.1,11,3.3,14.4
		s5.2,5.1,9.1,5.1c3.6,0,6.4-1.6,8.2-4.9C1299.4,296.4,1300.4,291.6,1300.4,285.6z"
                />
                <path
                  fill="currentColor"
                  d="M1361.5,303.4c0,1.6-1.3,2.4-4,2.4c-3,0-5.3-1.2-7-3.7c-0.7-1.1-1.2-2.1-1.4-3.1c-0.2-1-0.5-3.5-0.9-7.6
		c-0.2-2.5-1-4.2-2.4-5.2c-1.3-1-3.5-1.6-6.6-1.6v18.1c0,0.8,0.6,1.3,1.8,1.4c0.3,0,1.2,0.1,2.7,0.1c0.5,0,0.7,0.2,0.7,0.5
		c0,0.3-0.3,0.5-1,0.5h-0.7c-0.3,0-2,0-5,0c-0.6,0-1.6,0-2.9,0c-1.3,0-2.2,0-2.7,0h-2.2c-0.8,0-1.3-0.2-1.3-0.5
		c0-0.3,0.6-0.4,1.7-0.5c1.8,0,2.8-0.3,2.9-0.7c0.2-0.2,0.2-0.9,0.2-2.2v-31.8c0-1.5-0.2-2.5-0.5-2.8c-0.3-0.3-1.3-0.5-3-0.5
		c-1,0-1.4-0.2-1.4-0.5c0-0.4,0.3-0.6,0.9-0.6c0.1,0,0.3,0,0.5,0.1l7.7,0.2c0.8,0,1.8,0,3.2-0.1c1.5-0.1,2.6-0.2,3.2-0.2
		c3.6,0,6.4,0.8,8.5,2.4c2.1,1.6,3.1,3.8,3.1,6.5c0,2.7-1.1,4.9-3.2,6.7c-2.2,1.7-5.1,2.8-8.8,3.1c-0.3,0-0.5,0.1-0.6,0.2
		c0,0.2,0.2,0.2,0.6,0.2c4,0.7,6.8,2.1,8.3,4.2c1.5,2.1,2.5,6,3,11.6c0.3,3.2,1.4,4.7,3.2,4.7c0.6,0,0.9-0.1,0.9-0.3
		c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.3-0.8-0.3-1c0-0.8,0.4-1.2,1.3-1.2C1361,301.9,1361.5,302.4,1361.5,303.4z M1349.6,274.5
		c0-5.5-2.4-8.2-7.1-8.2c-1.3,0-2.1,0.2-2.6,0.5c-0.5,0.3-0.7,1-0.7,1.8v14.6c3.7,0,6.3-0.6,8-2
		C1348.8,279.9,1349.6,277.6,1349.6,274.5z"
                />
                <path
                  fill="currentColor"
                  d="M1412.1,275.4c0,0.5-0.2,0.7-0.7,0.7c-0.5,0-0.8-0.5-1-1.5c-0.1-0.7-0.2-1.4-0.2-2.1c-0.3-1.4-0.7-2.6-1.3-3.5
		c-0.8-1.3-2.1-2.1-4-2.4c-1.2-0.2-2.2-0.3-2.9-0.3c-1.8,0-2.9,0.1-3.3,0.1v34.8c0,1.4,0.4,2.3,1.1,2.6c0.4,0.2,1.5,0.3,3.2,0.3
		c0.5,0,0.7,0.2,0.7,0.5c0,0.3-0.3,0.5-0.9,0.5c-0.3,0-0.6,0-0.8-0.1c-2.3-0.1-3.9-0.1-4.9-0.1c-1.3,0-2.5,0-3.6,0.1
		c-1.1,0.1-2.5,0.1-4.3,0.1c-0.9,0-1.3-0.2-1.3-0.5c0-0.3,0.8-0.5,2.5-0.6c1.2,0,2-0.2,2.3-0.7c0.2-0.3,0.3-1.1,0.3-2.4v-34.6
		c-3.8,0-6.2,0.2-7.1,0.6c-2.4,0.9-3.8,3.2-4.3,7.1c-0.1,1.2-0.4,1.7-0.8,1.7c-0.5,0-0.7-0.4-0.7-1.2c0-0.4,0-0.8,0.1-1.3l0.1-1.3
		c0.1-1.8,0.3-4.1,0.5-7h2.6l11.8,0.2c1.4,0,3.5,0,6.5,0l9.6-0.2c0.1,2.2,0.3,5.3,0.7,9.6c0,0.1,0,0.3,0.1,0.4
		C1412.1,275.2,1412.1,275.3,1412.1,275.4z"
                />
                <path
                  fill="currentColor"
                  d="M1486.7,293.8c0,3.3-1.3,6.1-3.8,8.4c-2.5,2.3-5.5,3.5-9.1,3.5c-2.1,0-4.5-0.6-7.4-1.9c-0.8-0.3-1.3-0.5-1.7-0.5
		c-0.5,0-0.7,0.5-0.9,1.6c0,0.5-0.3,0.8-0.7,0.8c-0.4,0-0.6-0.2-0.6-0.7c0-0.3,0-0.5,0.1-0.7c0.3-2.3,0.5-4.5,0.5-6.5
		c0-0.4,0-1.5-0.1-3.2c0-0.2-0.1-0.5-0.1-0.9c0-0.6,0.2-0.9,0.7-0.9c0.4,0,0.7,0.5,0.8,1.4c1,6.9,4.2,10.4,9.7,10.4
		c2.3,0,4.2-0.7,5.4-2c1.3-1.3,1.9-3.2,1.9-5.6c0-2.1-0.6-4-1.9-5.5c-1.2-1.5-3.4-3-6.4-4.5c-3.6-1.8-6.2-3.6-7.6-5.2
		c-1.4-1.7-2.1-3.8-2.1-6.3c0-3.2,1-5.8,3.1-7.9c2.1-2.1,4.7-3.1,7.8-3.1c1.3,0,3.1,0.4,5.4,1.3c0.8,0.3,1.4,0.5,1.8,0.5
		c0.4,0,0.7-0.1,0.8-0.2c0.1-0.2,0.3-0.6,0.4-1.2c0.1-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.2,0.6,0.7c0,0.3,0,0.5-0.1,0.7
		c-0.1,0.9-0.2,2-0.2,3.3c0,1.8,0.1,3.3,0.3,4.4l0.1,0.9c0,0.4-0.1,0.6-0.5,0.6c-0.4,0-0.7-0.4-0.9-1.3c-0.4-2.3-1.4-4.2-3-5.7
		c-1.6-1.5-3.3-2.3-5.3-2.3c-1.9,0-3.4,0.6-4.6,1.8c-1.1,1.2-1.7,2.9-1.7,5c0,2.5,0.8,4.4,2.5,5.9c0.9,0.8,2.9,2,6,3.6
		c3.6,1.8,6.2,3.6,7.8,5.5C1485.9,289.3,1486.7,291.5,1486.7,293.8z"
                />
                <path
                  fill="currentColor"
                  d="M1538.8,295.8c0,0.2,0,1.1-0.1,2.8c-0.1,1.7-0.1,3.4-0.1,5v1.9c-3.8-0.2-11.2-0.3-22.4-0.3c-1.3,0-2.7,0-4,0
		c-1.3,0-2.2,0-2.7,0h-0.6c-0.7,0-1.1-0.2-1.1-0.5c0-0.2,0.1-0.3,0.3-0.4c0.1,0,0.8-0.1,2.2-0.2c1-0.1,1.6-0.3,1.8-0.6
		c0.2-0.3,0.3-1.3,0.3-2.7v-32.5c0-1.1-0.2-1.8-0.5-2c-0.3-0.2-1.4-0.3-3.1-0.3c-0.6,0-0.9-0.2-0.9-0.5c0-0.3,0.4-0.5,1.3-0.5h9.9
		c0.3,0,2.5,0,6.5,0c4,0,6.7,0,8.1,0c1.2,0,2.2-0.1,3.2-0.2c0.1,1.6,0.2,3.9,0.5,6.9l0.2,2.5c0,0.2,0.1,0.4,0.1,0.7
		c0,0.4-0.2,0.6-0.5,0.6c-0.4,0-0.7-0.5-0.9-1.5c-0.7-3.5-1.9-5.7-3.6-6.6c-1.2-0.7-4.5-1-9.7-1h-4.7v16.9h1.4c4.3,0,6.9-0.3,7.9-1
		c0.9-0.7,1.6-2.6,1.9-5.7c0.1-0.8,0.3-1.2,0.6-1.2c0.4,0,0.6,0.3,0.6,1c0,0.7,0,1.7-0.1,3.1c-0.1,1.3-0.1,2.4-0.1,3.1
		c0,4.1,0.1,6.9,0.2,8.5c0,0.4,0,0.7,0,1c0,0.5-0.2,0.7-0.6,0.7c-0.3,0-0.4-0.2-0.5-0.5c-0.1-0.3-0.3-1.4-0.5-3.2
		c-0.3-2.3-1.2-3.7-2.8-4.2c-0.7-0.2-2.5-0.3-5.4-0.3h-2.6v19.3c8.4,0,13.4-0.2,14.9-0.5c2.5-0.6,4-3,4.3-7.3
		c0.1-0.9,0.3-1.4,0.7-1.4C1538.6,294.6,1538.8,295,1538.8,295.8z"
                />
                <path
                  fill="currentColor"
                  d="M1592.2,303.4c0,1.6-1.3,2.4-4,2.4c-3,0-5.3-1.2-7-3.7c-0.7-1.1-1.2-2.1-1.4-3.1c-0.2-1-0.5-3.5-0.9-7.6
		c-0.2-2.5-1-4.2-2.4-5.2c-1.3-1-3.5-1.6-6.6-1.6v18.1c0,0.8,0.6,1.3,1.8,1.4c0.3,0,1.2,0.1,2.7,0.1c0.5,0,0.7,0.2,0.7,0.5
		c0,0.3-0.3,0.5-1,0.5h-0.7c-0.3,0-2,0-5,0c-0.6,0-1.6,0-2.9,0c-1.3,0-2.2,0-2.7,0h-2.2c-0.8,0-1.3-0.2-1.3-0.5
		c0-0.3,0.6-0.4,1.7-0.5c1.8,0,2.8-0.3,2.9-0.7c0.2-0.2,0.2-0.9,0.2-2.2v-31.8c0-1.5-0.2-2.5-0.5-2.8c-0.3-0.3-1.3-0.5-3-0.5
		c-1,0-1.4-0.2-1.4-0.5c0-0.4,0.3-0.6,0.9-0.6c0.1,0,0.3,0,0.5,0.1l7.7,0.2c0.8,0,1.8,0,3.2-0.1c1.5-0.1,2.6-0.2,3.2-0.2
		c3.6,0,6.4,0.8,8.5,2.4c2.1,1.6,3.1,3.8,3.1,6.5c0,2.7-1.1,4.9-3.2,6.7c-2.2,1.7-5.1,2.8-8.8,3.1c-0.3,0-0.5,0.1-0.6,0.2
		c0,0.2,0.2,0.2,0.6,0.2c4,0.7,6.8,2.1,8.3,4.2c1.5,2.1,2.5,6,3,11.6c0.3,3.2,1.4,4.7,3.2,4.7c0.6,0,0.9-0.1,0.9-0.3
		c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.3-0.8-0.3-1c0-0.8,0.4-1.2,1.3-1.2C1591.7,301.9,1592.2,302.4,1592.2,303.4z M1580.3,274.5
		c0-5.5-2.4-8.2-7.1-8.2c-1.3,0-2.1,0.2-2.6,0.5c-0.5,0.3-0.7,1-0.7,1.8v14.6c3.7,0,6.3-0.6,8-2
		C1579.5,279.9,1580.3,277.6,1580.3,274.5z"
                />
                <path
                  fill="currentColor"
                  d="M1647.6,265.4c0,0.3-0.4,0.5-1.1,0.5c-1.7,0-2.8,0.3-3.4,0.7c-0.6,0.5-1.2,1.5-1.8,3.2l-2,5.4c-4.7,13-8,22.9-10,29.9
		c-0.1,0.5-0.3,0.7-0.6,0.7c-0.3,0-0.9-1.4-1.9-4.3c-1.7-5.3-3.1-9.2-4-11.9c-1-2.9-2.5-7.3-4.6-13.1l-2.8-7.8
		c-0.4-1.1-0.8-1.8-1.3-2.2c-0.5-0.4-1.2-0.6-2.3-0.6c-1.3,0-2-0.1-2.3-0.1s-0.4-0.2-0.4-0.5c0-0.3,0.4-0.5,1.1-0.5h0.5
		c0.7,0,1.9,0,3.6,0.1s2.9,0.1,3.7,0.1c0.2,0,0.9,0,2.2-0.1c1.3-0.1,3.1-0.1,5.5-0.1h0.8c0.7,0,1,0.2,1,0.5c0,0.4-0.7,0.6-2.1,0.6
		l-3.5,0.1c-0.8,0-1.2,0.3-1.2,0.8c0,0.1,0,0.2,0.1,0.5l0.4,1.6c0.1,0.3,2,5.7,5.6,16.2c0.6,1.8,1.8,5.3,3.5,10.3
		c6.4-18,9.5-27.4,9.5-28.3c0-0.9-0.9-1.3-2.8-1.2h-1.8c-0.5,0-0.7-0.2-0.7-0.5c0-0.4,0.6-0.6,1.7-0.6c0.2,0,0.9,0,2,0
		c1.1,0.1,1.9,0.2,2.4,0.2h1.8l3.8-0.2C1647.2,264.9,1647.6,265.1,1647.6,265.4z"
                />
                <path
                  fill="currentColor"
                  d="M1681,304.6c0,0.3-0.2,0.5-0.7,0.5h-0.6c-0.2-0.1-2.9-0.2-7.9-0.2c-1,0-1.9,0-2.9,0.1c-0.5,0-1.3,0.1-2.3,0.1
		c-0.5,0-0.8-0.2-0.8-0.5c0-0.3,0.5-0.5,1.5-0.5c1.5,0,2.4-0.2,2.7-0.5c0.3-0.3,0.4-1.4,0.4-3.3v-30.4c0-1.9-0.1-2.9-0.3-3.2
		c-0.2-0.4-1.3-0.6-3.2-0.7c-0.7,0-1.1-0.2-1.1-0.4c0-0.3,0.2-0.5,0.7-0.5h7.9c0.6,0,1.7,0,3.4,0.1l2.2-0.1c0.6,0,0.9,0.2,0.9,0.5
		c0,0.3-0.9,0.5-2.6,0.5c-1,0-1.6,0.2-1.8,0.5c-0.2,0.3-0.3,1.1-0.3,2.4v30.8c0,2.2,0.1,3.4,0.3,3.6c0.4,0.4,1.7,0.7,3.8,0.7
		C1680.8,304.1,1681,304.3,1681,304.6z"
                />
                <path
                  fill="currentColor"
                  d="M1735.6,292.2c0,0,0,0.5-0.1,1.6c-0.2,2.2-0.2,4.3-0.2,6.5c0,0.3,0,0.8,0.1,1.5c0.1,0.7,0.1,1.6,0.1,2.6v0.5
		c0,0.5-0.2,0.7-0.5,0.7c-0.3,0-0.6-0.3-0.8-0.9c-0.5-1.3-1.1-2.2-1.6-2.5c-2.8,2.4-6.1,3.6-10,3.6c-5.6,0-10.3-2-14-5.9
		c-3.7-3.9-5.6-8.9-5.6-14.9c0-6,1.7-11,5.1-14.8c3.4-3.8,7.8-5.7,13.2-5.7c2,0,3.5,0.2,4.7,0.5c1.2,0.3,2.6,1,4.3,2
		c0.8,0.5,1.5,0.7,1.9,0.7c0.3,0,0.7-0.3,1-0.9c0.5-1.1,0.9-1.6,1.2-1.6c0.3,0,0.5,0.2,0.5,0.5c0,0.2,0,0.4-0.1,0.7
		c-0.4,1.8-0.6,5.3-0.6,10.4c0,0.7-0.1,1.2-0.2,1.3c-0.1,0.1-0.3,0.2-0.5,0.2c-0.4,0-0.6-0.4-0.7-1.1c-0.5-3.1-1.2-5.4-2.1-6.9
		c-1.9-3.1-4.8-4.6-8.9-4.6c-3.1,0-5.6,1-7.4,3.1c-3.3,3.7-4.9,9.2-4.9,16.4c0,6.4,1.3,11.4,4,14.9c2.3,3.1,5.5,4.6,9.3,4.6
		c2,0,3.8-0.5,5.5-1.5c1.7-1,2.9-2.4,3.8-4.1c0.9-1.7,1.6-3.8,2.1-6.3c0.2-0.9,0.4-1.4,0.7-1.4
		C1735.4,291.3,1735.6,291.6,1735.6,292.2z"
                />
                <path
                  fill="currentColor"
                  d="M1788.5,295.8c0,0.2,0,1.1-0.1,2.8c-0.1,1.7-0.1,3.4-0.1,5v1.9c-3.8-0.2-11.2-0.3-22.4-0.3c-1.3,0-2.7,0-4,0
		c-1.3,0-2.2,0-2.7,0h-0.6c-0.7,0-1.1-0.2-1.1-0.5c0-0.2,0.1-0.3,0.3-0.4c0.1,0,0.8-0.1,2.2-0.2c1-0.1,1.6-0.3,1.8-0.6
		c0.2-0.3,0.3-1.3,0.3-2.7v-32.5c0-1.1-0.2-1.8-0.5-2c-0.3-0.2-1.4-0.3-3.1-0.3c-0.6,0-0.9-0.2-0.9-0.5c0-0.3,0.4-0.5,1.3-0.5h9.9
		c0.3,0,2.5,0,6.5,0s6.7,0,8.1,0c1.2,0,2.2-0.1,3.2-0.2c0.1,1.6,0.2,3.9,0.5,6.9l0.2,2.5c0,0.2,0.1,0.4,0.1,0.7
		c0,0.4-0.2,0.6-0.5,0.6c-0.4,0-0.7-0.5-0.9-1.5c-0.7-3.5-1.9-5.7-3.6-6.6c-1.2-0.7-4.5-1-9.7-1h-4.7v16.9h1.4c4.3,0,6.9-0.3,7.9-1
		c0.9-0.7,1.6-2.6,1.9-5.7c0.1-0.8,0.3-1.2,0.6-1.2c0.4,0,0.6,0.3,0.6,1c0,0.7,0,1.7-0.1,3.1c-0.1,1.3-0.1,2.4-0.1,3.1
		c0,4.1,0.1,6.9,0.2,8.5c0,0.4,0,0.7,0,1c0,0.5-0.2,0.7-0.6,0.7c-0.3,0-0.5-0.2-0.5-0.5c-0.1-0.3-0.3-1.4-0.5-3.2
		c-0.3-2.3-1.2-3.7-2.8-4.2c-0.7-0.2-2.5-0.3-5.4-0.3h-2.6v19.3c8.4,0,13.4-0.2,14.9-0.5c2.5-0.6,4-3,4.3-7.3
		c0.1-0.9,0.3-1.4,0.7-1.4C1788.3,294.6,1788.5,295,1788.5,295.8z"
                />
              </g>
              <g>
                <rect
                  x="531.2"
                  y="283.7"
                  fill="currentColor"
                  width="170.6"
                  height="3.6"
                />
                <rect
                  x="1847.7"
                  y="283.7"
                  fill="currentColor"
                  width="172.3"
                  height="3.6"
                />
              </g>
              <g>
                <path
                  fill="currentColor"
                  d="M535.2,105.8c0-6.9-0.4-14.4-1.3-22.6c9.8,0.3,26.4,0.5,49.8,0.5c23.3,0,39.8-0.2,49.5-0.5
		c-0.8,8.4-1.2,15.9-1.2,22.6c0,5.9,0.2,10.7,0.5,14.5h-4c-0.9-9.1-2.1-15.9-3.5-20.3c-1.4-4.4-3.9-7.6-7.4-9.6
		c-3.5-2-8.9-3.1-16.2-3.1h-9.6v100.4c0,4.9,0.5,8.5,1.4,10.7c0.9,2.3,2.6,3.8,5,4.7c2.4,0.9,6.2,1.4,11.5,1.6v3.5
		c-6.2-0.3-14.8-0.5-25.9-0.5c-11.8,0-20.5,0.2-26.1,0.5v-3.5c5.2-0.2,9-0.7,11.5-1.6c2.4-0.9,4.1-2.4,5-4.7
		c0.9-2.3,1.4-5.8,1.4-10.7V87.3h-9.6c-7.3,0-12.7,1-16.2,3.1c-3.5,2-6,5.3-7.4,9.6c-1.4,4.4-2.6,11.2-3.5,20.3h-4
		C535,116.6,535.2,111.8,535.2,105.8z"
                />
                <path
                  fill="currentColor"
                  d="M731.5,88.4c8.4,5.1,14.9,12.4,19.6,22c4.6,9.6,6.9,21.1,6.9,34.3c0,13-2.4,24.5-7.1,34.5
		c-4.8,10-11.4,17.7-19.9,23.3c-8.5,5.5-18.2,8.3-29,8.3c-11.2,0-21-2.5-29.4-7.6c-8.4-5.1-14.9-12.4-19.6-22
		c-4.6-9.6-6.9-21.1-6.9-34.3c0-13,2.4-24.5,7.1-34.5c4.8-10,11.4-17.7,19.9-23.3c8.5-5.5,18.2-8.3,29-8.3
		C713.3,80.8,723.1,83.3,731.5,88.4z M681.5,91.8c-5.7,5.3-10.2,12.6-13.3,22c-3.1,9.4-4.7,20.1-4.7,32.3c0,12.2,1.7,23,5.2,32.3
		s8.1,16.5,14.1,21.5c5.9,5.1,12.5,7.6,19.8,7.6c7.5,0,14.1-2.6,19.9-7.9c5.7-5.3,10.1-12.6,13.2-22c3.1-9.4,4.7-20.1,4.7-32.3
		c0-12.2-1.7-23-5.2-32.3s-8.1-16.5-14-21.5c-5.9-5.1-12.5-7.6-19.7-7.6C693.9,84,687.2,86.6,681.5,91.8z"
                />
                <path
                  fill="currentColor"
                  d="M802.3,189.5c0,4.4,0.5,7.6,1.4,9.6c0.9,2,2.6,3.4,5,4.1c2.4,0.7,6.2,1.2,11.5,1.4v3.5
		c-6.1-0.3-14.7-0.5-25.9-0.5c-10.1,0-17.6,0.2-22.6,0.5v-3.5c4.2-0.2,7.3-0.6,9.3-1.4c1.9-0.7,3.2-2.1,4-4.1c0.7-2,1.1-5.2,1.1-9.6
		V102c0-4.4-0.4-7.6-1.1-9.6c-0.7-2-2-3.4-4-4.1c-1.9-0.7-5-1.2-9.3-1.4v-3.5c4.9,0.3,12.1,0.5,21.5,0.5c4.6,0,9.7-0.1,15.2-0.2
		c5.5-0.1,9.3-0.2,11.4-0.2c9.2,0,17.1,1.5,23.4,4.3c6.4,2.9,11.2,7,14.3,12.2c3.2,5.2,4.8,11.3,4.8,18.3c0,6.4-1.4,12.6-4.3,18.6
		c-2.9,6-7.8,10.9-14.7,14.9c-6.9,4-16.1,5.9-27.4,5.9h-13.6V189.5z M838.1,144.9c4.4-6.1,6.6-14.4,6.6-24.8c0-7.6-1-13.8-2.9-18.7
		c-2-4.9-5.1-8.5-9.3-11c-4.3-2.4-9.9-3.7-17-3.7c-3.7,0-6.4,0.4-8.3,1.2c-1.8,0.8-3.1,2.3-3.8,4.4c-0.7,2.1-1,5.3-1,9.5v52.2h11.9
		C825.7,154.2,833.6,151.1,838.1,144.9z"
                />
                <path
                  fill="currentColor"
                  d="M1007.9,200.2c-0.7-1.6-1.6-2.5-2.9-2.5c-0.9,0-2.1,0.5-3.6,1.5c-3.5,2.5-6.7,4.5-9.7,6.1
		c-3,1.6-6.3,2.9-10.1,3.9c-3.8,1-8.1,1.5-12.8,1.5c-11.2,0-21-2.5-29.4-7.6c-8.4-5.1-14.9-12.4-19.6-22c-4.6-9.6-6.9-21.1-6.9-34.3
		c0-13,2.4-24.5,7.1-34.5c4.8-10,11.4-17.7,19.9-23.3c8.5-5.5,18.2-8.3,29-8.3c7,0,13,1,18,3c5,2,9.8,4.8,14.5,8.4
		c1.3,1,2.4,1.6,3.4,1.6c2.4,0,3.9-3.5,4.5-10.5h4c-0.5,7.6-0.7,21.2-0.7,40.6h-4c-0.9-5.3-1.7-9.4-2.4-12.3
		c-0.7-2.9-1.6-5.4-2.5-7.5c-1-2-2.3-4.1-4-6.3c-3.6-4.6-8.1-8-13.5-10.3c-5.5-2.3-11.3-3.4-17.7-3.4c-7.6,0-14.3,2.5-20.1,7.6
		c-5.7,5.1-10.2,12.3-13.4,21.6c-3.2,9.4-4.7,20.3-4.7,32.9c0,12.8,1.7,23.8,5.2,33c3.4,9.2,8.2,16.2,14.2,20.9
		c6,4.8,12.9,7.1,20.5,7.1c5.9,0,11.6-1.1,17.1-3.4c5.6-2.2,9.9-5.6,13-10.1c2.4-3.2,4.1-6.7,5.2-10.5c1-3.8,2-9.6,3-17.4h4
		c0,20.3,0.2,34.4,0.7,42.4h-4C1009,204.5,1008.5,201.8,1007.9,200.2z"
                />
                <path
                  fill="currentColor"
                  d="M1117.9,88.4c8.4,5.1,14.9,12.4,19.6,22c4.6,9.6,6.9,21.1,6.9,34.3c0,13-2.4,24.5-7.1,34.5
		c-4.8,10-11.4,17.7-19.9,23.3c-8.5,5.5-18.2,8.3-29,8.3c-11.2,0-21-2.5-29.4-7.6c-8.4-5.1-14.9-12.4-19.6-22
		c-4.6-9.6-6.9-21.1-6.9-34.3c0-13,2.4-24.5,7.1-34.5c4.8-10,11.4-17.7,19.9-23.3c8.5-5.5,18.2-8.3,29-8.3
		C1099.7,80.8,1109.5,83.3,1117.9,88.4z M1067.9,91.8c-5.7,5.3-10.2,12.6-13.3,22c-3.1,9.4-4.7,20.1-4.7,32.3
		c0,12.2,1.7,23,5.2,32.3s8.1,16.5,14.1,21.5c5.9,5.1,12.5,7.6,19.8,7.6c7.5,0,14.1-2.6,19.9-7.9c5.7-5.3,10.1-12.6,13.2-22
		c3.1-9.4,4.7-20.1,4.7-32.3c0-12.2-1.7-23-5.2-32.3s-8.1-16.5-14-21.5c-5.9-5.1-12.5-7.6-19.7-7.6
		C1080.3,84,1073.7,86.6,1067.9,91.8z"
                />
                <path
                  fill="currentColor"
                  d="M1287.7,88.2c-1.9,0.7-3.2,2.1-4,4.1c-0.7,2-1.1,5.2-1.1,9.6v87.5c0,4.4,0.4,7.6,1.1,9.6c0.7,2,2,3.4,4,4.1
		c1.9,0.7,5,1.2,9.3,1.4v3.5c-5.5-0.3-13-0.5-22.4-0.5c-10.1,0-17.6,0.2-22.6,0.5v-3.5c4.2-0.2,7.3-0.6,9.2-1.4
		c1.9-0.7,3.2-2.1,3.9-4.1c0.7-2,1.1-5.2,1.1-9.6V95.1l-42.5,114h-2.8l-44.4-114.5v93.1c0,4.5,0.4,8,1.2,10.3c0.8,2.3,2.3,3.9,4.5,5
		c2.2,1,5.6,1.6,10.3,1.8v3.5c-4.2-0.3-10.2-0.5-18-0.5c-7,0-12.5,0.2-16.4,0.5v-3.5c4.1-0.2,7.1-0.8,9.1-1.8c2-1,3.3-2.7,4.1-5
		c0.7-2.3,1.1-5.7,1.1-10.3V102c0-4.4-0.4-7.6-1.1-9.6c-0.7-2-2-3.4-4-4.1c-1.9-0.7-5-1.2-9.3-1.4v-3.5c3.8,0.3,9.3,0.5,16.4,0.5
		c6.2,0,11.2-0.2,15.1-0.5l38.8,102.3l37.9-101.8h8.4c9.4,0,16.9-0.2,22.4-0.5v3.5C1292.7,87,1289.6,87.4,1287.7,88.2z"
                />
                <path
                  fill="currentColor"
                  d="M1340.6,189.5c0,4.4,0.5,7.6,1.4,9.6c0.9,2,2.6,3.4,5,4.1c2.4,0.7,6.2,1.2,11.5,1.4v3.5
		c-6.1-0.3-14.7-0.5-25.9-0.5c-10.1,0-17.6,0.2-22.6,0.5v-3.5c4.3-0.2,7.3-0.6,9.3-1.4c1.9-0.7,3.2-2.1,4-4.1c0.7-2,1.1-5.2,1.1-9.6
		V102c0-4.4-0.4-7.6-1.1-9.6c-0.7-2-2-3.4-4-4.1c-1.9-0.7-5-1.2-9.3-1.4v-3.5c4.9,0.3,12.1,0.5,21.5,0.5c4.6,0,9.7-0.1,15.2-0.2
		c5.5-0.1,9.3-0.2,11.4-0.2c9.2,0,17.1,1.5,23.4,4.3c6.4,2.9,11.2,7,14.3,12.2c3.2,5.2,4.8,11.3,4.8,18.3c0,6.4-1.4,12.6-4.3,18.6
		c-2.9,6-7.8,10.9-14.7,14.9c-6.9,4-16.1,5.9-27.4,5.9h-13.6V189.5z M1376.4,144.9c4.4-6.1,6.6-14.4,6.6-24.8c0-7.6-1-13.8-2.9-18.7
		c-2-4.9-5.1-8.5-9.3-11c-4.3-2.4-9.9-3.7-17-3.7c-3.7,0-6.4,0.4-8.3,1.2c-1.8,0.8-3.1,2.3-3.8,4.4c-0.7,2.1-1,5.3-1,9.5v52.2h11.9
		C1364,154.2,1372,151.1,1376.4,144.9z"
                />
                <path
                  fill="currentColor"
                  d="M1509.1,204.6v3.5c-4.8-0.3-11.1-0.5-19-0.5c-10.1,0-17.6,0.2-22.6,0.5v-3.5c4.5-0.2,7.7-0.7,9.6-1.6
		c1.9-0.9,2.9-2.6,2.9-4.9c0-2-0.7-4.8-2.1-8.3l-10.9-28.9h-42.6l-7.2,18.7c-2.4,6-3.5,10.8-3.5,14.5c0,3.7,1.3,6.4,3.7,8
		c2.5,1.6,6.4,2.5,11.6,2.6v3.5c-6.7-0.3-13.5-0.5-20.3-0.5c-5.3,0-9.7,0.2-13.2,0.5v-3.5c5.9-1.3,10.5-6.4,13.9-15.3L1451,82.4h2.8
		l43,110.9C1499.7,200.7,1503.8,204.4,1509.1,204.6z M1446.1,104.7l-20.2,52.6h40L1446.1,104.7z"
                />
                <path
                  fill="currentColor"
                  d="M1611.7,88.6c-2,1-3.3,2.7-4.1,5c-0.7,2.3-1.1,5.7-1.1,10.3V209h-4l-74.1-114.5v93.1c0,4.5,0.4,8,1.2,10.3
		c0.8,2.3,2.3,3.9,4.5,5c2.2,1,5.6,1.6,10.3,1.8v3.5c-4.2-0.3-10.2-0.5-18-0.5c-7,0-12.5,0.2-16.4,0.5v-3.5c4.1-0.2,7.1-0.8,9.1-1.8
		c2-1,3.3-2.7,4.1-5c0.7-2.3,1.1-5.7,1.1-10.3V102c0-4.4-0.4-7.6-1.1-9.6c-0.7-2-2-3.4-4-4.1c-1.9-0.7-5-1.2-9.3-1.4v-3.5
		c3.8,0.3,9.3,0.5,16.4,0.5c5.3,0,9.9-0.2,13.8-0.5l62.3,96v-75.5c0-4.5-0.4-8-1.2-10.3c-0.8-2.3-2.3-3.9-4.5-5
		c-2.2-1-5.7-1.6-10.3-1.8v-3.5c4.2,0.3,10.2,0.5,18,0.5c7,0,12.5-0.2,16.4-0.5v3.5C1616.7,87,1613.7,87.6,1611.7,88.6z"
                />
                <path
                  fill="currentColor"
                  d="M1668.5,88.2c-1.9,0.7-3.2,2.1-4,4.1c-0.7,2-1.1,5.2-1.1,9.6v87.5c0,4.4,0.4,7.6,1.1,9.6c0.7,2,2,3.4,4,4.1
		c1.9,0.7,5,1.2,9.3,1.4v3.5c-5.4-0.3-12.9-0.5-22.4-0.5c-10.1,0-17.6,0.2-22.6,0.5v-3.5c4.3-0.2,7.3-0.6,9.3-1.4
		c1.9-0.7,3.2-2.1,4-4.1c0.7-2,1.1-5.2,1.1-9.6V102c0-4.4-0.4-7.6-1.1-9.6c-0.7-2-2-3.4-4-4.1c-1.9-0.7-5-1.2-9.3-1.4v-3.5
		c4.9,0.3,12.4,0.5,22.6,0.5c9.5,0,17-0.2,22.4-0.5v3.5C1673.5,87,1670.4,87.4,1668.5,88.2z"
                />
                <path
                  fill="currentColor"
                  d="M1776.9,88.4c8.4,5.1,14.9,12.4,19.6,22c4.6,9.6,6.9,21.1,6.9,34.3c0,13-2.4,24.5-7.2,34.5
		c-4.8,10-11.4,17.7-19.9,23.3c-8.5,5.5-18.2,8.3-29,8.3c-11.2,0-21-2.5-29.4-7.6c-8.4-5.1-14.9-12.4-19.6-22
		c-4.6-9.6-6.9-21.1-6.9-34.3c0-13,2.4-24.5,7.2-34.5c4.8-10,11.4-17.7,19.9-23.3c8.5-5.5,18.2-8.3,29-8.3
		C1758.7,80.8,1768.5,83.3,1776.9,88.4z M1726.9,91.8c-5.7,5.3-10.2,12.6-13.3,22c-3.1,9.4-4.7,20.1-4.7,32.3
		c0,12.2,1.7,23,5.2,32.3s8.1,16.5,14.1,21.5c5.9,5.1,12.5,7.6,19.8,7.6c7.5,0,14.1-2.6,19.9-7.9c5.7-5.3,10.1-12.6,13.2-22
		c3.1-9.4,4.7-20.1,4.7-32.3c0-12.2-1.7-23-5.2-32.3s-8.1-16.5-14-21.5c-5.9-5.1-12.5-7.6-19.7-7.6
		C1739.3,84,1732.6,86.6,1726.9,91.8z"
                />
                <path
                  fill="currentColor"
                  d="M1918.7,88.6c-2,1-3.3,2.7-4.1,5c-0.7,2.3-1.1,5.7-1.1,10.3V209h-4l-74.1-114.5v93.1c0,4.5,0.4,8,1.2,10.3
		c0.8,2.3,2.3,3.9,4.5,5c2.2,1,5.6,1.6,10.3,1.8v3.5c-4.2-0.3-10.2-0.5-18-0.5c-7,0-12.5,0.2-16.4,0.5v-3.5c4.1-0.2,7.1-0.8,9.1-1.8
		c2-1,3.3-2.7,4.1-5c0.7-2.3,1.1-5.7,1.1-10.3V102c0-4.4-0.4-7.6-1.1-9.6c-0.7-2-2-3.4-4-4.1c-1.9-0.7-5-1.2-9.3-1.4v-3.5
		c3.8,0.3,9.3,0.5,16.4,0.5c5.3,0,9.9-0.2,13.8-0.5l62.3,96v-75.5c0-4.5-0.4-8-1.2-10.3c-0.8-2.3-2.3-3.9-4.5-5
		c-2.2-1-5.7-1.6-10.3-1.8v-3.5c4.2,0.3,10.2,0.5,18,0.5c7,0,12.5-0.2,16.4-0.5v3.5C1923.7,87,1920.7,87.6,1918.7,88.6z"
                />
                <path
                  fill="currentColor"
                  d="M1957.1,196.1c5.1,7.4,12.8,11.1,23.3,11.1c4,0,7.8-0.8,11.2-2.5c3.5-1.7,6.3-4.3,8.4-7.8
		c2.2-3.5,3.2-7.9,3.2-13.2c0-4.5-1.1-8.6-3.3-12.2c-2.2-3.6-5.2-6.9-9-9.9c-3.8-3-8.8-6.4-15.2-10.2c-7-4.2-12.6-8-16.9-11.3
		c-4.3-3.3-7.7-7.3-10.5-11.9c-2.7-4.6-4.1-10.1-4.1-16.3c0-6.7,1.5-12.3,4.5-16.9c3-4.6,7-8,12.1-10.3c5-2.3,10.6-3.4,16.6-3.4
		c3.6,0,6.7,0.3,9.3,0.9c2.6,0.6,4.8,1.3,6.5,2.2c1.7,0.9,3.6,2,5.7,3.4c2.6,1.7,4.7,2.5,6,2.5c1.1,0,1.9-0.6,2.5-1.9
		c0.6-1.2,1-3.2,1.4-5.9h4.1c-0.6,6.9-0.9,18.9-0.9,36h-4c-0.5-6-1.8-11.6-3.9-16.7c-2.2-5.1-5.3-9.3-9.5-12.4
		c-4.2-3.2-9.3-4.8-15.5-4.8c-4.1,0-7.8,0.9-11.1,2.6c-3.3,1.7-5.8,4.1-7.7,7.2c-1.8,3.1-2.8,6.7-2.8,10.7c0,6.4,1.9,11.5,5.8,15.4
		c3.8,3.9,8.3,7.4,13.5,10.6c5.1,3.1,8.6,5.2,10.4,6.3c7.4,4.7,13.3,8.7,17.6,12.1c4.3,3.4,7.8,7.4,10.3,11.9
		c2.6,4.5,3.9,9.7,3.9,15.7c0,7.2-1.7,13.4-5,18.4c-3.3,5-7.8,8.8-13.5,11.3c-5.7,2.5-12,3.8-19,3.8c-5.6,0-10.2-0.6-13.9-1.8
		c-3.6-1.2-7-2.8-10.2-4.8c-2.6-1.7-4.7-2.5-6-2.5c-1.1,0-1.9,0.6-2.5,1.9c-0.6,1.2-1,3.2-1.4,5.9h-4.1c0.5-6.4,0.7-20.5,0.7-42.3
		h4.1C1949.1,179,1952,188.7,1957.1,196.1z"
                />
              </g>
              <path
                fill="#FCB42F"
                d="M465.2,166.9c-14.3-2-28.1-3-41.1-3c-18.5,0-35.7,2-51.8,6c6.6-22.4,8.7-47.7,6.5-76.4l-0.9-12.1l-11.3,4.5
	c-27.1,10.8-49,24.1-66.4,40.1c-5.6-31.7-20.3-63.5-44.4-96.3l-7.2-9.8l-7.2,9.8c-24.1,32.8-38.8,64.6-44.4,96.3
	c-17.3-16.1-39.3-29.3-66.4-40.1l-11.3-4.5l-0.9,12.1c-2.2,28.7-0.1,54,6.5,76.4c-16.1-4-33.3-6-51.8-6c-13,0-26.8,1-41.1,3l-12,1.7
	l5.2,11c21.6,45.8,48.5,77.8,82.4,98c29.7,17.7,65.1,26.3,108.4,26.3c0,0,0,0,0,0c10.3,0,21-0.5,32.6-1.4c11.6,1,22.3,1.4,32.6,1.4
	c43.3,0,78.7-8.6,108.4-26.3c33.9-20.2,60.8-52.2,82.4-98l5.2-11L465.2,166.9z M361.7,107.4c1.2,39.1-7,70.6-24.9,96.1
	c-13.8,19.7-33.7,36.3-61.6,51.5c17-31.9,25.8-61,27.5-89.7c0.3-5.4,0.3-10.8,0.1-16.1C317.4,132.5,336.9,118.7,361.7,107.4z
	 M248.6,50.5c26.4,39,38.2,76.3,36.2,113.6c-1.8,31.5-13.3,63.9-36.2,101.1c-22.8-37.2-34.4-69.6-36.2-101.1
	C210.4,126.9,222.2,89.6,248.6,50.5z M135.6,107.4c24.8,11.3,44.4,25.1,59,41.7c-0.2,5.4-0.2,10.7,0.1,16.1
	c1.6,28.8,10.5,57.9,27.5,89.8c-27.9-15.2-47.8-31.8-61.6-51.5C142.6,178,134.4,146.5,135.6,107.4z M216,285.9
	c-40,0-72.4-7.8-99.3-23.7c-28-16.6-50.9-42.6-69.9-79.1c9.1-0.9,17.9-1.3,26.3-1.3c21.8,0,41.5,3,59.6,9
	c3.7,8.1,8.1,15.7,13.2,22.9c18.5,26.3,46.1,47.5,86.8,66.5c1,1.7,2,3.4,3,5.2C228.9,285.8,222.4,285.9,216,285.9L216,285.9z
	 M380.5,262.2c-26.8,16-59.3,23.7-99.3,23.7c-6.4,0-12.9-0.2-19.7-0.6c1-1.7,2-3.5,3-5.2c40.6-19,68.2-40.2,86.8-66.5
	c5.1-7.2,9.5-14.9,13.2-22.9c18-6.1,37.8-9,59.6-9c8.4,0,17.2,0.5,26.3,1.3C431.4,219.6,408.5,245.6,380.5,262.2z"
              />
            </svg>
          </StyledLogoIcon>
        )
      default:
        return (
          <StyledLogoIcon width="3.4267em">
            <StyledLogoLabel>TopCompanions</StyledLogoLabel>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2040 623.7">
              <g>
                <g>
                  <path
                    fill="currentColor"
                    d="M374.1,596.6c0,1.3-0.1,3.6-0.2,6.7H373c-0.3,0-1.1-0.1-2.2-0.2c-1.2-0.1-2.5-0.2-4-0.2l-32.1-0.2c-0.9,0-1.3-0.2-1.3-0.7
  c0-0.4,0.7-0.6,2.1-0.7c2-0.1,3.1-0.3,3.5-0.7c0.4-0.3,0.6-1.3,0.6-2.9v-43.5c0-1.9-0.2-3-0.6-3.3c-0.4-0.3-1.8-0.5-4.1-0.5
  c-1,0-1.5-0.3-1.5-0.9c0-0.5,0.3-0.7,0.9-0.7h0.7c1.7,0.2,5.1,0.2,10.1,0.2c0.5,0,1.4,0,2.7-0.1c1.3-0.1,2.5-0.1,3.3-0.1h0.9
  c0.9,0,1.4,0.3,1.4,0.8c0,0.3-0.1,0.5-0.4,0.6c-0.3,0.1-1.2,0.1-2.7,0.2c-1.5,0.1-2.4,0.3-2.7,0.7c-0.3,0.4-0.4,1.8-0.4,4.1v45.7
  c1.4,0.1,3.3,0.1,5.8,0.1c6,0,10.3-0.3,12.8-0.9c2.5-0.6,4.2-1.8,5-3.5c0.9-1.7,1.4-4,1.5-6.9c0-0.7,0.3-1,0.9-1
  c0.6,0,0.9,0.4,0.9,1.2v1.9c0,0.1,0,0.3,0,0.7c0,0.4,0,0.7,0,1.1V596.6z"
                  />
                  <path
                    fill="currentColor"
                    d="M449.9,549.6c0,0.4-0.5,0.6-1.4,0.6c-0.2,0-0.7,0-1.5,0c-0.3,0-0.6,0-1.1,0c-1.3,0-2.1,0.3-2.3,1
  c-0.1,0.4-0.2,0.9-0.2,1.8v29c0,7.5-1.4,13-4.2,16.4c-2.8,3.4-7.3,5.1-13.6,5.1c-7.5,0-12.7-2.1-15.8-6.3c-2-2.8-3-7.5-3-14v-31
  c-0.2-0.9-0.8-1.5-1.9-1.7c-0.3-0.1-1.3-0.1-2.9-0.1c-0.9-0.1-1.3-0.4-1.3-0.8c0-0.5,0.4-0.7,1.1-0.7h1.4c0.3,0,0.9,0,1.8,0
  c0.8,0,1.6,0,2.2,0h11.1c0.3,0,0.6,0,0.9,0c0.9,0,1.3,0.3,1.3,0.8c0,0.4-0.2,0.6-0.5,0.6c-0.3,0.1-1.3,0.1-3.1,0.2
  c-0.7,0-1.2,0.1-1.5,0.2c-0.5,0.2-0.8,0.6-0.9,1.1c-0.1,0.2-0.1,1.7-0.1,4.4v27.6c0,6.8,1.1,11.4,3.2,13.9
  c2.2,2.5,5.4,3.7,9.7,3.7c7.8,0,12.3-4.4,13.3-13.3c0.2-1.3,0.2-7,0.2-17v-4.4v-6.2v-5.3c0-0.5,0-1.1,0-1.7c0-0.7,0-0.9,0-0.8
  c0-1.1-0.5-1.8-1.6-2.1c-0.4-0.1-1.4-0.2-3.2-0.2c-1,0-1.5-0.3-1.5-0.9c0-0.5,0.4-0.7,1.1-0.7c0.4,0,0.8,0,1,0c0.6,0,2.9,0,6.8,0
  c0.6,0,1.2,0,1.9,0c0.7,0,1.2,0,1.5,0h1.2C449.3,549,449.9,549.2,449.9,549.6z"
                  />
                  <path
                    fill="currentColor"
                    d="M524.7,602c0,0.5-0.7,0.7-2.2,0.7c-1.8,0-3.6,0-5.4,0c-1.8,0-3.6,0-5.4,0c-2.3,0-4.9,0-7.9,0c-1.5,0-2.6,0-3.3,0
  c-1.5,0-2.2-0.2-2.2-0.7c0-0.4,0.6-0.6,1.8-0.6h3.5c1.2,0.1,2,0,2.2-0.1c0.8-0.2,1.2-0.6,1.2-1.5c0-0.8-0.7-2.5-2-5.1l-8.2-16.7
  l-9.7,16.5c-1.7,3-2.5,4.7-2.5,5.2c0,0.9,0.9,1.5,2.6,1.7c-0.1,0,1.1,0,3.5,0c0.9,0,1.4,0.2,1.4,0.7c0,0.4-0.4,0.6-1.2,0.6h-8.7
  h-6.6c-0.8,0-1.2-0.2-1.2-0.7c0-0.5,0.7-0.8,2-0.9c0.9-0.1,1.9-0.2,2.8-0.3c0.8-0.2,1.4-0.5,1.9-1c1-0.9,4.2-5.9,9.5-15.2
  c2-3.4,3.7-6.4,5.3-8.9l-8.8-16.6c-1.8-3.4-3.1-5.6-3.8-6.6s-1.4-1.7-2.2-2c-0.8-0.2-2.5-0.2-4.9-0.2c-0.6,0-0.9-0.2-0.9-0.6
  c0-0.6,0.4-0.9,1.2-0.9c1.1,0,3.5,0.1,7,0.2c1.8,0.1,3,0.1,3.6,0.1c6,0,9.4-0.1,10-0.2h1.1c1.1,0,1.6,0.2,1.6,0.6
  c0,0.6-0.9,0.9-2.7,0.9c-3.4-0.1-5.1,0.4-5.1,1.2c0,0.9,2.9,7,8.8,18.2c2.1-3.1,4.4-6.8,6.9-11.1c2.5-4.3,3.8-6.7,3.8-7.2
  c0-0.8-1.4-1.2-4.3-1.2c-1.4,0-2.1-0.2-2.1-0.7c0-0.5,0.5-0.8,1.5-0.8c0.7,0,1.6,0,2.9,0.1c1.3,0.1,2.3,0.1,2.9,0.1
  c0.3,0,1.5,0,3.6-0.1c2.1-0.1,3.4-0.1,3.8-0.1h0.7c0.9,0,1.3,0.2,1.3,0.7c0,0.4-0.3,0.7-1,0.7c-0.1,0-0.9,0-2.5,0.1
  c-1.8,0.1-3,0.4-3.6,0.9c-0.6,0.6-1.7,2.2-3.3,5c-2,3.6-5,8.4-8.8,14.4l-0.9,1.3c0.4,0.7,3.8,7.5,10.4,20.4
  c2.3,4.4,3.9,7.1,4.9,7.9c1,0.9,2.9,1.3,5.8,1.2C524,601.3,524.7,601.5,524.7,602z"
                  />
                  <path
                    fill="currentColor"
                    d="M598.3,549.6c0,0.4-0.5,0.6-1.4,0.6c-0.2,0-0.7,0-1.5,0c-0.3,0-0.6,0-1.1,0c-1.3,0-2.1,0.3-2.3,1
  c-0.1,0.4-0.2,0.9-0.2,1.8v29c0,7.5-1.4,13-4.2,16.4c-2.8,3.4-7.3,5.1-13.6,5.1c-7.5,0-12.7-2.1-15.8-6.3c-2-2.8-3-7.5-3-14v-31
  c-0.2-0.9-0.8-1.5-1.9-1.7c-0.3-0.1-1.3-0.1-2.9-0.1c-0.9-0.1-1.3-0.4-1.3-0.8c0-0.5,0.4-0.7,1.1-0.7h1.4c0.3,0,0.9,0,1.8,0
  c0.8,0,1.6,0,2.2,0h11.1c0.3,0,0.6,0,0.9,0c0.9,0,1.3,0.3,1.3,0.8c0,0.4-0.2,0.6-0.5,0.6c-0.3,0.1-1.3,0.1-3.1,0.2
  c-0.7,0-1.2,0.1-1.5,0.2c-0.5,0.2-0.8,0.6-0.9,1.1c-0.1,0.2-0.1,1.7-0.1,4.4v27.6c0,6.8,1.1,11.4,3.2,13.9
  c2.2,2.5,5.4,3.7,9.7,3.7c7.8,0,12.3-4.4,13.3-13.3c0.2-1.3,0.2-7,0.2-17v-4.4v-6.2v-5.3c0-0.5,0-1.1,0-1.7c0-0.7,0-0.9,0-0.8
  c0-1.1-0.5-1.8-1.6-2.1c-0.4-0.1-1.4-0.2-3.2-0.2c-1,0-1.5-0.3-1.5-0.9c0-0.5,0.4-0.7,1.1-0.7c0.4,0,0.8,0,1,0c0.6,0,2.9,0,6.8,0
  c0.6,0,1.2,0,1.9,0c0.7,0,1.2,0,1.5,0h1.2C597.7,549,598.3,549.2,598.3,549.6z"
                  />
                  <path
                    fill="currentColor"
                    d="M669.1,600.3c0,2.2-1.8,3.3-5.4,3.3c-4,0-7.2-1.7-9.4-5c-1-1.4-1.6-2.8-1.9-4.2c-0.3-1.3-0.7-4.7-1.3-10.2
  c-0.3-3.3-1.4-5.6-3.2-7c-1.8-1.4-4.7-2.1-8.8-2.2v24.4c0,1,0.8,1.7,2.5,1.9c0.5,0.1,1.7,0.1,3.6,0.2c0.7,0,1,0.2,1,0.6
  c0,0.5-0.4,0.7-1.3,0.7h-0.9c-0.5,0-2.7,0-6.7,0c-0.8,0-2.1,0-3.9,0c-1.8,0-3,0-3.6,0h-3c-1.1,0-1.7-0.2-1.7-0.7
  c0-0.4,0.7-0.6,2.2-0.6c2.4-0.1,3.7-0.4,3.9-0.9c0.2-0.3,0.3-1.3,0.3-3v-42.7c0-2.1-0.2-3.3-0.7-3.7c-0.4-0.4-1.8-0.6-4-0.7
  c-1.3,0-1.9-0.2-1.9-0.7c0-0.5,0.4-0.8,1.2-0.8c0.2,0,0.4,0,0.7,0.1l10.3,0.2c1,0,2.5,0,4.3-0.1c2.1-0.1,3.5-0.2,4.3-0.2
  c4.8,0,8.6,1.1,11.4,3.2c2.8,2.1,4.2,5,4.2,8.7c0,3.6-1.4,6.6-4.3,8.9c-2.9,2.3-6.8,3.7-11.8,4.1c-0.5,0.1-0.7,0.1-0.8,0.2
  c0,0.2,0.3,0.3,0.8,0.3c5.4,0.9,9.1,2.8,11.2,5.7c2,2.9,3.4,8.1,4.1,15.6c0.4,4.2,1.9,6.4,4.3,6.4c0.8,0,1.2-0.2,1.2-0.5
  c0-0.1,0-0.1-0.1-0.2c-0.3-0.6-0.4-1-0.4-1.4c0-1.1,0.6-1.6,1.7-1.6C668.5,598.4,669.1,599.1,669.1,600.3z M653.1,561.6
  c0-7.4-3.2-11.1-9.6-11.1c-1.7,0-2.9,0.2-3.5,0.7c-0.6,0.5-0.9,1.3-0.9,2.5v19.7c5,0.1,8.5-0.9,10.7-2.7
  C652,568.8,653.1,565.7,653.1,561.6z"
                  />
                  <path
                    fill="currentColor"
                    d="M740.8,549.5c0,0.4-0.4,0.6-1.3,0.6c-2.8,0.1-4.6,0.5-5.3,1.5c-0.6,0.6-2.8,5-6.7,13.3c-1.7,3.7-3.3,6.9-4.8,9.7
  c-1,1.9-1.6,3.2-1.8,3.9c-0.2,0.7-0.2,2.2-0.2,4.5v14.7c0,1.7,0.2,2.7,0.7,3c0.4,0.3,2,0.5,4.6,0.7c1.4,0.1,2.1,0.3,2.1,0.7
  c0,0.4-0.4,0.6-1.1,0.6h-0.9l-10.8-0.2c-3,0-5.5,0.1-7.5,0.2c-0.3,0.1-0.7,0.1-1.1,0.1c-0.7,0-1.1-0.2-1.1-0.7
  c0-0.5,0.6-0.8,1.8-0.8c2.8-0.1,4.5-0.4,5-0.8c0.5-0.4,0.8-1.6,0.8-3.7v-16.3c-0.5-1.1-2-4.2-4.5-9.1l-7.7-15.3
  c-0.8-1.5-1.7-3.2-2.8-5c-0.6-0.5-1.8-0.8-3.7-0.8c-1.9,0-2.8-0.3-2.8-0.9c0-0.4,0.4-0.6,1.2-0.6c1.1,0,3.2,0.1,6.5,0.2
  c1.9,0.1,3.2,0.1,4,0.1l9-0.1c0.4-0.1,0.8-0.1,1.2-0.1c0.9,0,1.4,0.3,1.4,0.8c0,0.5-0.9,0.7-2.6,0.7c-3.2,0-4.8,0.6-4.8,1.7
  c0,0.9,1.6,4.4,4.9,10.7l7.1,13.4c1.3-2.3,2.8-5.2,4.4-8.6l4.6-9.2c1.7-3.3,2.5-5.5,2.5-6.6c0-0.7-0.4-1.1-1.3-1.2
  c-1.8-0.3-3-0.4-3.6-0.3c-1.1,0.1-1.7-0.2-1.7-0.8c0-0.4,0.5-0.6,1.5-0.6h4.5h2.6C738.2,548.6,740.8,548.8,740.8,549.5z"
                  />
                  <path
                    fill="currentColor"
                    d="M846.2,590.2c0,0.2-0.1,1.5-0.2,3.8c-0.1,2.3-0.2,4.5-0.2,6.7v2.6c-5.1-0.3-15.1-0.5-30-0.5c-1.8,0-3.6,0-5.3,0
  c-1.8,0-2.9,0-3.6,0h-0.8c-1,0-1.5-0.2-1.5-0.6c0-0.3,0.2-0.4,0.5-0.5c0.2,0,1.1-0.1,2.9-0.2c1.3-0.1,2.2-0.4,2.4-0.9
  c0.3-0.5,0.4-1.7,0.4-3.6v-43.6c0-1.5-0.2-2.4-0.7-2.7c-0.4-0.3-1.8-0.4-4.2-0.4c-0.8,0-1.2-0.2-1.2-0.7c0-0.4,0.6-0.7,1.7-0.7
  h13.2c0.5,0,3.4,0,8.7,0s9,0,10.8,0c1.5,0,3-0.1,4.3-0.3c0.1,2.2,0.3,5.3,0.7,9.2l0.3,3.3c0.1,0.3,0.1,0.6,0.1,1
  c0,0.5-0.2,0.8-0.7,0.8c-0.6,0-1-0.7-1.2-2c-1-4.7-2.6-7.6-4.8-8.9c-1.7-0.9-6-1.4-13-1.4h-6.3v22.8h1.9c5.8,0,9.3-0.4,10.6-1.3
  c1.3-0.9,2.1-3.4,2.6-7.7c0.1-1.1,0.4-1.6,0.9-1.6c0.6,0,0.9,0.5,0.9,1.4c0,0.9-0.1,2.3-0.2,4.1c-0.1,1.8-0.2,3.2-0.2,4.1
  c0,5.5,0.1,9.3,0.2,11.4c0,0.5,0.1,1,0.1,1.3c0,0.7-0.3,1-0.8,1c-0.4,0-0.6-0.2-0.7-0.7c-0.1-0.4-0.3-1.9-0.7-4.3
  c-0.4-3-1.7-4.9-3.8-5.6c-0.9-0.3-3.3-0.5-7.2-0.5h-3.5V601c11.3,0,18-0.2,20-0.7c3.4-0.8,5.3-4,5.8-9.8c0.1-1.3,0.4-1.9,1-1.9
  C845.9,588.6,846.2,589.1,846.2,590.2z"
                  />
                  <path
                    fill="currentColor"
                    d="M908.3,587.6c0,4.4-1.7,8.2-5.1,11.3c-3.4,3.1-7.4,4.7-12.2,4.7c-2.8,0-6.1-0.9-10-2.6c-1-0.5-1.8-0.7-2.3-0.7
  c-0.6,0-1,0.7-1.2,2.1c-0.1,0.7-0.4,1.1-1,1.1c-0.5,0-0.8-0.3-0.8-0.9c0-0.4,0-0.7,0.1-0.9c0.4-3.1,0.6-6.1,0.6-8.7
  c0-0.5-0.1-2-0.2-4.3c-0.1-0.3-0.1-0.7-0.1-1.2c0-0.8,0.3-1.2,0.9-1.2c0.5,0,0.9,0.6,1.1,1.9c1.3,9.3,5.7,13.9,13.1,13.9
  c3.1,0,5.6-0.9,7.3-2.7c1.7-1.8,2.6-4.3,2.6-7.5c0-2.9-0.8-5.4-2.5-7.4c-1.7-2-4.6-4.1-8.6-6.1c-4.9-2.4-8.3-4.8-10.2-7
  c-1.9-2.3-2.9-5.1-2.9-8.4c0-4.3,1.4-7.8,4.1-10.6c2.8-2.8,6.3-4.2,10.5-4.2c1.8,0,4.2,0.6,7.3,1.8c1.1,0.4,1.9,0.6,2.4,0.6
  c0.6,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.8,0.5-1.6c0.1-0.5,0.4-0.8,0.9-0.8c0.5,0,0.8,0.3,0.8,0.9c0,0.4,0,0.7-0.1,0.9
  c-0.2,1.2-0.2,2.7-0.2,4.5c0,2.5,0.1,4.4,0.4,5.9l0.1,1.2c0.1,0.6-0.2,0.9-0.7,0.9c-0.6,0-1-0.6-1.2-1.8c-0.6-3-1.9-5.6-4-7.6
  c-2.1-2-4.5-3.1-7.1-3.1c-2.6,0-4.6,0.8-6.2,2.5c-1.5,1.7-2.3,3.9-2.3,6.7c0,3.3,1.1,6,3.3,8c1.2,1.1,3.9,2.7,8,4.8
  c4.8,2.4,8.3,4.9,10.4,7.4C907.2,581.5,908.3,584.4,908.3,587.6z"
                  />
                  <path
                    fill="currentColor"
                    d="M981.9,585.3c0-0.1-0.1,0.6-0.2,2.1c-0.2,2.9-0.3,5.8-0.3,8.7c0,0.4,0,1,0.2,2c0.1,1,0.2,2.1,0.2,3.4v0.7
  c0,0.7-0.2,1-0.6,1c-0.4,0-0.8-0.4-1.1-1.2c-0.7-1.8-1.4-2.9-2.2-3.3c-3.7,3.3-8.2,4.9-13.4,4.9c-7.6,0-13.9-2.6-18.8-7.9
  c-5-5.3-7.5-12-7.5-20c0-8.1,2.3-14.7,6.8-19.9c4.6-5.1,10.5-7.7,17.7-7.7c2.6,0,4.7,0.2,6.3,0.7c1.6,0.4,3.5,1.3,5.8,2.7
  c1.1,0.7,2,1,2.6,1c0.5,0,0.9-0.4,1.3-1.2c0.7-1.4,1.2-2.2,1.6-2.2c0.4,0,0.6,0.2,0.6,0.6c0,0.2-0.1,0.5-0.2,1
  c-0.6,2.5-0.9,7.1-0.9,13.9c0,1-0.1,1.5-0.2,1.7c-0.2,0.2-0.4,0.2-0.6,0.2c-0.5,0-0.9-0.5-1-1.5c-0.7-4.1-1.6-7.2-2.9-9.2
  c-2.5-4.1-6.5-6.2-11.9-6.2c-4.2,0-7.5,1.4-9.9,4.2c-4.4,5-6.6,12.3-6.6,22c0,8.6,1.8,15.3,5.4,20c3.1,4.1,7.3,6.2,12.5,6.2
  c2.7,0,5.1-0.7,7.3-2c2.2-1.3,3.9-3.2,5.1-5.5c1.2-2.3,2.2-5.2,2.8-8.5c0.3-1.2,0.6-1.9,1-1.9
  C981.6,584.2,981.9,584.6,981.9,585.3z"
                  />
                  <path
                    fill="currentColor"
                    d="M1061.9,576.1c0,7.7-2.3,14.2-6.9,19.5c-4.6,5.3-10.3,8-17,8c-7.1,0-13.1-2.7-18-8.1c-4.9-5.4-7.3-12-7.3-19.9
  c0-8.1,2.3-14.6,7-19.8c4.7-5.1,10.6-7.7,17.9-7.7c6.9,0,12.6,2.7,17.3,8C1059.6,561.5,1061.9,568.2,1061.9,576.1z M1053.3,576.5
  c0-9.5-1.9-16.8-5.8-22.1c-2.4-3.3-5.8-5-10.1-5c-4.1,0-7.3,1.3-9.6,3.8c-4.2,4.7-6.3,12.2-6.3,22.7c0,8.3,1.5,14.8,4.4,19.4
  c2.9,4.6,7,6.9,12.3,6.9c4.9,0,8.6-2.2,11.1-6.5C1051.9,590.9,1053.3,584.5,1053.3,576.5z"
                  />
                  <path
                    fill="currentColor"
                    d="M1135.4,600.3c0,2.2-1.8,3.3-5.4,3.3c-4,0-7.2-1.7-9.4-5c-1-1.4-1.6-2.8-1.9-4.2c-0.3-1.3-0.7-4.7-1.3-10.2
  c-0.3-3.3-1.4-5.6-3.2-7c-1.8-1.4-4.7-2.1-8.8-2.2v24.4c0,1,0.8,1.7,2.5,1.9c0.5,0.1,1.7,0.1,3.6,0.2c0.7,0,1,0.2,1,0.6
  c0,0.5-0.4,0.7-1.3,0.7h-0.9c-0.5,0-2.7,0-6.7,0c-0.8,0-2.1,0-3.9,0c-1.8,0-3,0-3.6,0h-3c-1.1,0-1.7-0.2-1.7-0.7
  c0-0.4,0.7-0.6,2.2-0.6c2.4-0.1,3.7-0.4,3.9-0.9c0.2-0.3,0.3-1.3,0.3-3v-42.7c0-2.1-0.2-3.3-0.7-3.7c-0.4-0.4-1.8-0.6-4-0.7
  c-1.3,0-1.9-0.2-1.9-0.7c0-0.5,0.4-0.8,1.2-0.8c0.2,0,0.4,0,0.7,0.1l10.3,0.2c1,0,2.5,0,4.3-0.1c2.1-0.1,3.5-0.2,4.3-0.2
  c4.8,0,8.6,1.1,11.4,3.2c2.8,2.1,4.2,5,4.2,8.7c0,3.6-1.4,6.6-4.3,8.9c-2.9,2.3-6.8,3.7-11.8,4.1c-0.5,0.1-0.7,0.1-0.8,0.2
  c0,0.2,0.3,0.3,0.8,0.3c5.4,0.9,9.1,2.8,11.2,5.7c2,2.9,3.4,8.1,4.1,15.6c0.4,4.2,1.9,6.4,4.3,6.4c0.8,0,1.2-0.2,1.2-0.5
  c0-0.1,0-0.1-0.1-0.2c-0.3-0.6-0.4-1-0.4-1.4c0-1.1,0.6-1.6,1.7-1.6C1134.8,598.4,1135.4,599.1,1135.4,600.3z M1119.4,561.6
  c0-7.4-3.2-11.1-9.6-11.1c-1.7,0-2.9,0.2-3.5,0.7c-0.6,0.5-0.9,1.3-0.9,2.5v19.7c5,0.1,8.5-0.9,10.7-2.7
  C1118.3,568.8,1119.4,565.7,1119.4,561.6z"
                  />
                  <path
                    fill="currentColor"
                    d="M1203.3,562.7c0,0.7-0.3,1-0.9,1s-1.1-0.7-1.3-2c-0.1-0.9-0.2-1.9-0.3-2.8c-0.4-1.9-1-3.4-1.8-4.7
  c-1.1-1.7-2.9-2.8-5.3-3.3c-1.6-0.3-2.9-0.4-3.9-0.4c-2.4,0.1-3.9,0.1-4.4,0.2v46.7c0,1.9,0.5,3,1.5,3.5c0.5,0.3,2,0.4,4.3,0.5
  c0.6,0,0.9,0.3,0.9,0.7c0,0.4-0.4,0.7-1.2,0.7c-0.4,0-0.8,0-1.1-0.1c-3.1-0.1-5.3-0.2-6.6-0.2c-1.7,0-3.3,0-4.8,0.1
  c-1.5,0.1-3.4,0.1-5.7,0.1c-1.2,0-1.8-0.2-1.8-0.6c0-0.5,1.1-0.7,3.3-0.8c1.6,0,2.6-0.3,3.1-1c0.3-0.4,0.4-1.5,0.4-3.2v-46.4
  c-5.1,0.1-8.3,0.3-9.5,0.8c-3.2,1.2-5.2,4.4-5.7,9.5c-0.2,1.5-0.5,2.3-1.1,2.3c-0.6,0-0.9-0.5-0.9-1.6c0-0.6,0-1.1,0.1-1.7
  l0.2-1.8c0.2-2.4,0.4-5.5,0.7-9.4h3.5l15.8,0.2c1.9,0.1,4.7,0.1,8.7,0l12.8-0.2c0.1,2.9,0.4,7.2,1,12.8c0,0.2,0,0.3,0.1,0.6
  C1203.3,562.5,1203.3,562.7,1203.3,562.7z"
                  />
                  <path
                    fill="currentColor"
                    d="M1303.6,587.6c0,4.4-1.7,8.2-5.1,11.3c-3.4,3.1-7.4,4.7-12.2,4.7c-2.8,0-6.1-0.9-10-2.6c-1-0.5-1.8-0.7-2.3-0.7
  c-0.6,0-1,0.7-1.2,2.1c-0.1,0.7-0.4,1.1-1,1.1c-0.5,0-0.8-0.3-0.8-0.9c0-0.4,0-0.7,0.1-0.9c0.4-3.1,0.6-6.1,0.6-8.7
  c0-0.5-0.1-2-0.2-4.3c-0.1-0.3-0.1-0.7-0.1-1.2c0-0.8,0.3-1.2,0.9-1.2c0.5,0,0.9,0.6,1.1,1.9c1.3,9.3,5.7,13.9,13.1,13.9
  c3.1,0,5.6-0.9,7.3-2.7c1.7-1.8,2.6-4.3,2.6-7.5c0-2.9-0.8-5.4-2.5-7.4c-1.7-2-4.6-4.1-8.6-6.1c-4.9-2.4-8.3-4.8-10.2-7
  c-1.9-2.3-2.9-5.1-2.9-8.4c0-4.3,1.4-7.8,4.1-10.6c2.8-2.8,6.3-4.2,10.5-4.2c1.8,0,4.2,0.6,7.3,1.8c1.1,0.4,1.9,0.6,2.4,0.6
  c0.6,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.8,0.5-1.6c0.1-0.5,0.4-0.8,0.9-0.8c0.5,0,0.8,0.3,0.8,0.9c0,0.4,0,0.7-0.1,0.9
  c-0.2,1.2-0.2,2.7-0.2,4.5c0,2.5,0.1,4.4,0.4,5.9l0.1,1.2c0.1,0.6-0.2,0.9-0.7,0.9c-0.6,0-1-0.6-1.2-1.8c-0.6-3-1.9-5.6-4-7.6
  c-2.1-2-4.5-3.1-7.1-3.1c-2.6,0-4.6,0.8-6.2,2.5c-1.5,1.7-2.3,3.9-2.3,6.7c0,3.3,1.1,6,3.3,8c1.2,1.1,3.9,2.7,8,4.8
  c4.8,2.4,8.3,4.9,10.4,7.4S1303.6,584.4,1303.6,587.6z"
                  />
                  <path
                    fill="currentColor"
                    d="M1373.6,590.2c0,0.2-0.1,1.5-0.2,3.8c-0.1,2.3-0.2,4.5-0.2,6.7v2.6c-5.1-0.3-15.1-0.5-30-0.5c-1.8,0-3.6,0-5.3,0
  c-1.8,0-2.9,0-3.6,0h-0.8c-1,0-1.5-0.2-1.5-0.6c0-0.3,0.2-0.4,0.5-0.5c0.2,0,1.1-0.1,2.9-0.2c1.3-0.1,2.2-0.4,2.4-0.9
  c0.3-0.5,0.4-1.7,0.4-3.6v-43.6c0-1.5-0.2-2.4-0.7-2.7c-0.4-0.3-1.8-0.4-4.2-0.4c-0.8,0-1.2-0.2-1.2-0.7c0-0.4,0.6-0.7,1.7-0.7
  h13.2c0.5,0,3.4,0,8.7,0c5.4,0,9,0,10.8,0c1.5,0,3-0.1,4.3-0.3c0.1,2.2,0.3,5.3,0.7,9.2l0.3,3.3c0.1,0.3,0.1,0.6,0.1,1
  c0,0.5-0.2,0.8-0.7,0.8c-0.6,0-1-0.7-1.2-2c-1-4.7-2.6-7.6-4.8-8.9c-1.7-0.9-6-1.4-13-1.4h-6.3v22.8h1.9c5.8,0,9.3-0.4,10.6-1.3
  c1.3-0.9,2.1-3.4,2.6-7.7c0.1-1.1,0.4-1.6,0.9-1.6c0.6,0,0.9,0.5,0.9,1.4c0,0.9-0.1,2.3-0.2,4.1c-0.1,1.8-0.2,3.2-0.2,4.1
  c0,5.5,0.1,9.3,0.2,11.4c0,0.5,0.1,1,0.1,1.3c0,0.7-0.3,1-0.8,1c-0.4,0-0.6-0.2-0.7-0.7c-0.1-0.4-0.3-1.9-0.7-4.3
  c-0.4-3-1.7-4.9-3.8-5.6c-0.9-0.3-3.3-0.5-7.2-0.5h-3.5V601c11.3,0,18-0.2,20-0.7c3.4-0.8,5.3-4,5.8-9.8c0.1-1.3,0.4-1.9,1-1.9
  C1373.3,588.6,1373.6,589.1,1373.6,590.2z"
                  />
                  <path
                    fill="currentColor"
                    d="M1445.3,600.3c0,2.2-1.8,3.3-5.4,3.3c-4,0-7.2-1.7-9.4-5c-1-1.4-1.6-2.8-1.9-4.2c-0.3-1.3-0.7-4.7-1.3-10.2
  c-0.3-3.3-1.4-5.6-3.2-7c-1.8-1.4-4.7-2.1-8.8-2.2v24.4c0,1,0.8,1.7,2.5,1.9c0.5,0.1,1.7,0.1,3.6,0.2c0.7,0,1,0.2,1,0.6
  c0,0.5-0.4,0.7-1.3,0.7h-0.9c-0.5,0-2.7,0-6.7,0c-0.8,0-2.1,0-3.9,0c-1.8,0-3,0-3.6,0h-3c-1.1,0-1.7-0.2-1.7-0.7
  c0-0.4,0.7-0.6,2.2-0.6c2.4-0.1,3.7-0.4,3.9-0.9c0.2-0.3,0.3-1.3,0.3-3v-42.7c0-2.1-0.2-3.3-0.7-3.7c-0.4-0.4-1.8-0.6-4-0.7
  c-1.3,0-1.9-0.2-1.9-0.7c0-0.5,0.4-0.8,1.2-0.8c0.2,0,0.4,0,0.7,0.1l10.3,0.2c1,0,2.5,0,4.3-0.1c2.1-0.1,3.5-0.2,4.3-0.2
  c4.8,0,8.6,1.1,11.4,3.2c2.8,2.1,4.2,5,4.2,8.7c0,3.6-1.4,6.6-4.3,8.9c-2.9,2.3-6.8,3.7-11.8,4.1c-0.5,0.1-0.7,0.1-0.8,0.2
  c0,0.2,0.3,0.3,0.8,0.3c5.4,0.9,9.1,2.8,11.2,5.7c2,2.9,3.4,8.1,4.1,15.6c0.4,4.2,1.9,6.4,4.3,6.4c0.8,0,1.2-0.2,1.2-0.5
  c0-0.1,0-0.1-0.1-0.2c-0.3-0.6-0.4-1-0.4-1.4c0-1.1,0.6-1.6,1.7-1.6C1444.7,598.4,1445.3,599.1,1445.3,600.3z M1429.3,561.6
  c0-7.4-3.2-11.1-9.6-11.1c-1.7,0-2.9,0.2-3.5,0.7c-0.6,0.5-0.9,1.3-0.9,2.5v19.7c5,0.1,8.5-0.9,10.7-2.7
  C1428.2,568.8,1429.3,565.7,1429.3,561.6z"
                  />
                  <path
                    fill="currentColor"
                    d="M1519.7,549.3c0,0.4-0.5,0.6-1.5,0.7c-2.3,0.1-3.8,0.4-4.6,1c-0.8,0.6-1.6,2.1-2.4,4.3l-2.6,7.2
  c-6.3,17.4-10.8,30.8-13.4,40.1c-0.2,0.6-0.4,0.9-0.8,0.9c-0.4,0-1.3-1.9-2.6-5.8c-2.3-7.1-4.1-12.4-5.4-15.9
  c-1.4-3.9-3.4-9.8-6.1-17.6l-3.8-10.4c-0.5-1.4-1.1-2.4-1.7-2.9c-0.6-0.5-1.7-0.8-3.1-0.8c-1.7-0.1-2.7-0.1-3.1-0.2
  s-0.5-0.3-0.5-0.6c0-0.5,0.5-0.7,1.5-0.7h0.7c0.9,0,2.5,0,4.8,0.1c2.3,0.1,3.9,0.1,4.9,0.1c0.3,0,1.3,0,3-0.1
  c1.7-0.1,4.2-0.1,7.4-0.1h1.1c0.9,0,1.4,0.2,1.4,0.7c0,0.5-0.9,0.8-2.8,0.8l-4.7,0.2c-1.1,0.1-1.6,0.4-1.6,1.1
  c0,0.1,0,0.3,0.1,0.7l0.5,2.1c0.1,0.4,2.6,7.7,7.6,21.8c0.8,2.4,2.4,7.1,4.7,13.9c8.5-24.1,12.8-36.8,12.8-38
  c0-1.2-1.3-1.7-3.8-1.6h-2.4c-0.6,0-0.9-0.2-0.9-0.7c0-0.6,0.8-0.9,2.3-0.9c0.3,0,1.2,0,2.6,0c1.4,0.2,2.5,0.2,3.2,0.2h2.5
  l5.1-0.2C1519.2,548.7,1519.7,548.9,1519.7,549.3z"
                  />
                  <path
                    fill="currentColor"
                    d="M1564.6,602c0,0.4-0.3,0.7-0.9,0.7h-0.8c-0.3-0.2-3.8-0.2-10.6-0.2c-1.3,0-2.6,0.1-3.9,0.2c-0.7,0.1-1.7,0.1-3.1,0.1
  c-0.7,0-1-0.2-1-0.7c0-0.4,0.7-0.6,2-0.7c2.1-0.1,3.3-0.3,3.6-0.7c0.4-0.4,0.5-1.9,0.5-4.5v-40.8c0-2.5-0.1-4-0.4-4.3
  c-0.3-0.5-1.8-0.8-4.3-0.9c-1,0-1.5-0.2-1.5-0.5c0-0.4,0.3-0.6,0.9-0.6h10.6c0.8,0,2.3,0,4.5,0.1l3-0.1c0.8,0,1.3,0.2,1.3,0.7
  c0,0.4-1.2,0.6-3.5,0.7c-1.3,0.1-2.1,0.3-2.4,0.7c-0.3,0.4-0.4,1.5-0.4,3.3v41.3c0,2.9,0.1,4.5,0.4,4.8c0.6,0.6,2.3,0.9,5.1,1
  C1564.3,601.3,1564.6,601.6,1564.6,602z"
                  />
                  <path
                    fill="currentColor"
                    d="M1637.9,585.3c0-0.1-0.1,0.6-0.2,2.1c-0.2,2.9-0.3,5.8-0.3,8.7c0,0.4,0,1,0.2,2c0.1,1,0.2,2.1,0.2,3.4v0.7
  c0,0.7-0.2,1-0.6,1c-0.4,0-0.8-0.4-1.1-1.2c-0.7-1.8-1.4-2.9-2.2-3.3c-3.7,3.3-8.2,4.9-13.4,4.9c-7.6,0-13.9-2.6-18.8-7.9
  c-5-5.3-7.5-12-7.5-20c0-8.1,2.3-14.7,6.8-19.9c4.6-5.1,10.5-7.7,17.7-7.7c2.6,0,4.7,0.2,6.3,0.7c1.6,0.4,3.5,1.3,5.8,2.7
  c1.1,0.7,2,1,2.6,1c0.5,0,0.9-0.4,1.3-1.2c0.7-1.4,1.2-2.2,1.6-2.2s0.6,0.2,0.6,0.6c0,0.2-0.1,0.5-0.2,1
  c-0.6,2.5-0.9,7.1-0.9,13.9c0,1-0.1,1.5-0.2,1.7c-0.2,0.2-0.4,0.2-0.6,0.2c-0.5,0-0.9-0.5-1-1.5c-0.7-4.1-1.6-7.2-2.9-9.2
  c-2.5-4.1-6.5-6.2-11.9-6.2c-4.2,0-7.5,1.4-9.9,4.2c-4.4,5-6.6,12.3-6.6,22c0,8.6,1.8,15.3,5.4,20c3.1,4.1,7.3,6.2,12.5,6.2
  c2.7,0,5.1-0.7,7.3-2c2.2-1.3,3.9-3.2,5.1-5.5c1.2-2.3,2.2-5.2,2.8-8.5c0.3-1.2,0.6-1.9,1-1.9
  C1637.7,584.2,1637.9,584.6,1637.9,585.3z"
                  />
                  <path
                    fill="currentColor"
                    d="M1709,590.2c0,0.2-0.1,1.5-0.2,3.8c-0.1,2.3-0.2,4.5-0.2,6.7v2.6c-5.1-0.3-15.1-0.5-30-0.5c-1.8,0-3.6,0-5.3,0
  c-1.8,0-2.9,0-3.6,0h-0.8c-1,0-1.5-0.2-1.5-0.6c0-0.3,0.2-0.4,0.5-0.5c0.2,0,1.1-0.1,2.9-0.2c1.3-0.1,2.2-0.4,2.4-0.9
  c0.3-0.5,0.4-1.7,0.4-3.6v-43.6c0-1.5-0.2-2.4-0.7-2.7c-0.4-0.3-1.8-0.4-4.2-0.4c-0.8,0-1.2-0.2-1.2-0.7c0-0.4,0.6-0.7,1.7-0.7
  h13.2c0.5,0,3.4,0,8.7,0s9,0,10.8,0c1.5,0,3-0.1,4.3-0.3c0.1,2.2,0.3,5.3,0.7,9.2l0.3,3.3c0.1,0.3,0.1,0.6,0.1,1
  c0,0.5-0.2,0.8-0.7,0.8c-0.6,0-1-0.7-1.2-2c-1-4.7-2.6-7.6-4.8-8.9c-1.7-0.9-6-1.4-13-1.4h-6.3v22.8h1.9c5.8,0,9.3-0.4,10.6-1.3
  c1.3-0.9,2.1-3.4,2.6-7.7c0.1-1.1,0.4-1.6,0.9-1.6c0.6,0,0.9,0.5,0.9,1.4c0,0.9-0.1,2.3-0.2,4.1c-0.1,1.8-0.2,3.2-0.2,4.1
  c0,5.5,0.1,9.3,0.2,11.4c0,0.5,0.1,1,0.1,1.3c0,0.7-0.3,1-0.8,1c-0.4,0-0.6-0.2-0.7-0.7c-0.1-0.4-0.3-1.9-0.7-4.3
  c-0.4-3-1.7-4.9-3.8-5.6c-0.9-0.3-3.3-0.5-7.2-0.5h-3.5V601c11.3,0,18-0.2,20-0.7c3.4-0.8,5.3-4,5.8-9.8c0.1-1.3,0.4-1.9,1-1.9
  C1708.8,588.6,1709,589.1,1709,590.2z"
                  />
                </g>
                <g>
                  <rect
                    x="20"
                    y="573.9"
                    fill="currentColor"
                    width="229.2"
                    height="4.8"
                  />
                  <rect
                    x="1788.5"
                    y="573.9"
                    fill="currentColor"
                    width="231.5"
                    height="4.8"
                  />
                </g>
                <g>
                  <path
                    fill="currentColor"
                    d="M25.3,335c0-9.3-0.6-19.4-1.7-30.3c13.1,0.5,35.4,0.7,66.9,0.7c31.2,0,53.4-0.2,66.5-0.7
  c-1.1,11.3-1.6,21.4-1.6,30.3c0,7.9,0.2,14.4,0.7,19.4h-5.4c-1.2-12.3-2.8-21.3-4.7-27.3c-1.9-5.9-5.2-10.2-10-13
  c-4.7-2.7-12-4.1-21.8-4.1h-12.8v134.8c0,6.6,0.6,11.4,1.9,14.4c1.2,3,3.5,5.2,6.7,6.3c3.2,1.2,8.4,1.9,15.4,2.1v4.7
  c-8.3-0.5-19.9-0.7-34.8-0.7c-15.8,0-27.5,0.2-35.1,0.7v-4.7c7-0.2,12.2-0.9,15.4-2.1c3.2-1.2,5.5-3.3,6.7-6.3
  c1.2-3,1.9-7.8,1.9-14.4V310.1H66.5c-9.8,0-17.1,1.4-21.8,4.1c-4.7,2.7-8.1,7.1-10,13c-1.9,5.9-3.5,15-4.7,27.3h-5.4
  C25.1,349.4,25.3,343,25.3,335z"
                  />
                  <path
                    fill="currentColor"
                    d="M289,311.5c11.3,6.8,20.1,16.6,26.3,29.6c6.2,12.9,9.3,28.3,9.3,46.1c0,17.4-3.2,32.9-9.6,46.3
  c-6.4,13.4-15.3,23.8-26.7,31.2c-11.4,7.4-24.4,11.1-39,11.1c-15,0-28.2-3.4-39.5-10.2c-11.3-6.8-20.1-16.6-26.3-29.6
  c-6.2-12.9-9.3-28.3-9.3-46.1c0-17.4,3.2-32.9,9.6-46.3c6.4-13.4,15.3-23.8,26.7-31.2c11.4-7.4,24.4-11.1,39-11.1
  C264.6,301.3,277.7,304.7,289,311.5z M221.9,316.2c-7.7,7.1-13.7,16.9-17.8,29.5c-4.2,12.6-6.2,27.1-6.2,43.3
  c0,16.4,2.3,30.9,6.9,43.4c4.6,12.5,10.9,22.1,18.9,28.9c8,6.8,16.8,10.2,26.6,10.2c10.1,0,19-3.5,26.7-10.6
  c7.7-7.1,13.6-16.9,17.8-29.5c4.2-12.6,6.2-27.1,6.2-43.3c0-16.4-2.3-30.9-6.9-43.4c-4.6-12.5-10.9-22.1-18.9-28.9
  c-7.9-6.8-16.8-10.2-26.5-10.2C238.5,305.6,229.6,309.1,221.9,316.2z"
                  />
                  <path
                    fill="currentColor"
                    d="M384.2,447.4c0,5.9,0.6,10.3,1.9,13c1.2,2.7,3.5,4.6,6.7,5.6c3.2,1,8.4,1.6,15.4,1.9v4.7
  c-8.2-0.5-19.8-0.7-34.8-0.7c-13.6,0-23.7,0.2-30.3,0.7v-4.7c5.7-0.2,9.9-0.8,12.4-1.9c2.6-1,4.4-2.9,5.3-5.6c1-2.7,1.4-7,1.4-13
  V329.8c0-5.9-0.5-10.3-1.4-13c-1-2.7-2.7-4.6-5.3-5.6c-2.6-1-6.7-1.6-12.4-1.9v-4.7c6.6,0.5,16.2,0.7,28.9,0.7
  c6.2,0,13-0.1,20.4-0.2c7.4-0.2,12.6-0.2,15.3-0.2c12.4,0,22.9,1.9,31.5,5.8c8.6,3.9,15,9.4,19.3,16.4c4.3,7,6.4,15.2,6.4,24.5
  c0,8.6-1.9,17-5.8,25c-3.9,8-10.4,14.7-19.7,20c-9.3,5.3-21.6,8-36.9,8h-18.3V447.4z M432.2,387.5c5.9-8.3,8.9-19.4,8.9-33.3
  c0-10.2-1.3-18.6-3.9-25.1c-2.6-6.6-6.8-11.5-12.6-14.8c-5.7-3.3-13.4-4.9-22.9-4.9c-4.9,0-8.6,0.6-11.1,1.7
  c-2.5,1.1-4.2,3.1-5.1,6c-0.9,2.9-1.4,7.1-1.4,12.7v70.1h16C415.6,399.9,426.2,395.8,432.2,387.5z"
                  />
                  <path
                    fill="currentColor"
                    d="M660.3,461.8c-0.9-2.2-2.2-3.3-3.9-3.3c-1.2,0-2.9,0.7-4.9,2c-4.7,3.3-9,6-13,8.2c-4,2.1-8.5,3.9-13.6,5.2
  c-5.1,1.3-10.8,2-17.2,2c-15,0-28.2-3.4-39.5-10.2c-11.3-6.8-20.1-16.6-26.3-29.6c-6.2-12.9-9.3-28.3-9.3-46.1
  c0-17.4,3.2-32.9,9.6-46.3c6.4-13.4,15.3-23.8,26.7-31.2c11.4-7.4,24.4-11.1,39-11.1c9.4,0,17.5,1.4,24.1,4.1
  c6.7,2.7,13.2,6.5,19.5,11.3c1.7,1.4,3.2,2.1,4.6,2.1c3.2,0,5.2-4.7,6-14.1h5.4c-0.6,10.3-0.9,28.4-0.9,54.5h-5.4
  c-1.2-7.1-2.2-12.6-3.2-16.5c-1-3.9-2.1-7.3-3.4-10c-1.3-2.7-3.1-5.6-5.3-8.5c-4.8-6.2-10.8-10.8-18.2-13.9
  c-7.3-3.1-15.2-4.6-23.7-4.6c-10.3,0-19.2,3.4-27,10.2c-7.7,6.8-13.7,16.5-17.9,29c-4.2,12.6-6.4,27.3-6.4,44.2
  c0,17.2,2.3,32,6.9,44.3c4.6,12.3,11,21.7,19.1,28.1c8.1,6.4,17.3,9.6,27.5,9.6c7.9,0,15.5-1.5,23-4.5c7.5-3,13.3-7.5,17.5-13.5
  c3.2-4.3,5.6-9,6.9-14.1c1.4-5.1,2.7-12.9,4.1-23.4h5.4c0,27.2,0.3,46.2,0.9,56.9h-5.4C661.8,467.5,661.2,464,660.3,461.8z"
                  />
                  <path
                    fill="currentColor"
                    d="M808.2,311.5c11.3,6.8,20.1,16.6,26.3,29.6c6.2,12.9,9.3,28.3,9.3,46.1c0,17.4-3.2,32.9-9.6,46.3
  c-6.4,13.4-15.3,23.8-26.7,31.2c-11.4,7.4-24.4,11.1-39,11.1c-15,0-28.2-3.4-39.5-10.2c-11.3-6.8-20.1-16.6-26.3-29.6
  c-6.2-12.9-9.3-28.3-9.3-46.1c0-17.4,3.2-32.9,9.6-46.3c6.4-13.4,15.3-23.8,26.7-31.2c11.4-7.4,24.4-11.1,39-11.1
  C783.7,301.3,796.9,304.7,808.2,311.5z M741,316.2c-7.7,7.1-13.7,16.9-17.8,29.5c-4.2,12.6-6.2,27.1-6.2,43.3
  c0,16.4,2.3,30.9,6.9,43.4c4.6,12.5,10.9,22.1,18.9,28.9c8,6.8,16.8,10.2,26.6,10.2c10.1,0,19-3.5,26.7-10.6
  c7.7-7.1,13.6-16.9,17.8-29.5c4.2-12.6,6.2-27.1,6.2-43.3c0-16.4-2.3-30.9-6.9-43.4c-4.6-12.5-10.9-22.1-18.9-28.9
  c-7.9-6.8-16.8-10.2-26.5-10.2C757.6,305.6,748.7,309.1,741,316.2z"
                  />
                  <path
                    fill="currentColor"
                    d="M1036.2,311.3c-2.6,1-4.4,2.9-5.3,5.6c-1,2.7-1.4,7-1.4,13v117.6c0,5.9,0.5,10.3,1.4,13
  c1,2.7,2.7,4.6,5.3,5.6c2.6,1,6.7,1.6,12.4,1.9v4.7c-7.4-0.5-17.4-0.7-30.1-0.7c-13.6,0-23.7,0.2-30.3,0.7v-4.7
  c5.7-0.2,9.8-0.8,12.4-1.9c2.5-1,4.3-2.9,5.3-5.6c1-2.7,1.4-7,1.4-13V320.5l-57,153.1h-3.8l-59.7-153.8v125.1
  c0,6.1,0.5,10.7,1.6,13.8c1.1,3.1,3.1,5.3,6.1,6.7c3,1.4,7.6,2.1,13.8,2.4v4.7c-5.6-0.5-13.7-0.7-24.2-0.7c-9.4,0-16.7,0.2-22,0.7
  v-4.7c5.5-0.2,9.5-1,12.2-2.4c2.7-1.3,4.5-3.6,5.5-6.7c1-3.1,1.5-7.7,1.5-13.8V329.8c0-5.9-0.5-10.3-1.4-13
  c-1-2.7-2.7-4.6-5.3-5.6c-2.6-1-6.7-1.6-12.4-1.9v-4.7c5.2,0.5,12.5,0.7,22,0.7c8.3,0,15.1-0.2,20.2-0.7l52.1,137.5l50.9-136.8
  h11.2c12.7,0,22.7-0.2,30.1-0.7v4.7C1042.9,309.7,1038.8,310.3,1036.2,311.3z"
                  />
                  <path
                    fill="currentColor"
                    d="M1107.3,447.4c0,5.9,0.6,10.3,1.9,13c1.2,2.7,3.5,4.6,6.7,5.6c3.2,1,8.4,1.6,15.4,1.9v4.7
  c-8.2-0.5-19.8-0.7-34.8-0.7c-13.6,0-23.7,0.2-30.3,0.7v-4.7c5.7-0.2,9.9-0.8,12.4-1.9c2.6-1,4.4-2.9,5.3-5.6c1-2.7,1.4-7,1.4-13
  V329.8c0-5.9-0.5-10.3-1.4-13c-1-2.7-2.7-4.6-5.3-5.6c-2.6-1-6.7-1.6-12.4-1.9v-4.7c6.6,0.5,16.2,0.7,28.9,0.7
  c6.2,0,13-0.1,20.4-0.2c7.4-0.2,12.6-0.2,15.3-0.2c12.4,0,22.9,1.9,31.5,5.8c8.6,3.9,15,9.4,19.3,16.4c4.3,7,6.4,15.2,6.4,24.5
  c0,8.6-1.9,17-5.8,25c-3.9,8-10.4,14.7-19.7,20c-9.3,5.3-21.6,8-36.9,8h-18.3V447.4z M1155.4,387.5c5.9-8.3,8.9-19.4,8.9-33.3
  c0-10.2-1.3-18.6-3.9-25.1c-2.6-6.6-6.8-11.5-12.6-14.8c-5.7-3.3-13.4-4.9-22.9-4.9c-4.9,0-8.6,0.6-11.1,1.7
  c-2.5,1.1-4.2,3.1-5.1,6c-0.9,2.9-1.4,7.1-1.4,12.7v70.1h16C1138.7,399.9,1149.4,395.8,1155.4,387.5z"
                  />
                  <path
                    fill="currentColor"
                    d="M1333.6,467.7v4.7c-6.5-0.5-15-0.7-25.5-0.7c-13.5,0-23.6,0.2-30.3,0.7v-4.7c6-0.2,10.3-1,12.9-2.2
  c2.6-1.2,3.9-3.4,3.9-6.6c0-2.7-0.9-6.4-2.8-11.1l-14.6-38.9h-57.2l-9.7,25.1c-3.2,8-4.7,14.5-4.7,19.4c0,5,1.7,8.6,5,10.8
  c3.4,2.2,8.5,3.3,15.6,3.5v4.7c-9-0.5-18.1-0.7-27.3-0.7c-7.1,0-13,0.2-17.7,0.7v-4.7c7.9-1.8,14.1-8.6,18.6-20.6l55.8-143.6h3.8
  l57.7,149C1321.1,462.4,1326.5,467.4,1333.6,467.7z M1249.1,333.5l-27.2,70.7h53.7L1249.1,333.5z"
                  />
                  <path
                    fill="currentColor"
                    d="M1471.5,311.8c-2.7,1.3-4.5,3.6-5.5,6.7c-1,3.1-1.5,7.7-1.5,13.8v141.4h-5.4l-99.5-153.8v125.1
  c0,6.1,0.5,10.7,1.6,13.8c1.1,3.1,3.1,5.3,6.1,6.7c3,1.4,7.6,2.1,13.8,2.4v4.7c-5.6-0.5-13.7-0.7-24.2-0.7c-9.4,0-16.7,0.2-22,0.7
  v-4.7c5.5-0.2,9.6-1,12.2-2.4c2.7-1.3,4.5-3.6,5.5-6.7c1-3.1,1.5-7.7,1.5-13.8V329.8c0-5.9-0.5-10.3-1.5-13
  c-1-2.7-2.7-4.6-5.3-5.6c-2.6-1-6.7-1.6-12.4-1.9v-4.7c5.2,0.5,12.5,0.7,22,0.7c7.1,0,13.3-0.2,18.5-0.7l83.7,128.9V332.2
  c0-6.1-0.5-10.7-1.6-13.8c-1-3.1-3.1-5.3-6.1-6.7c-3-1.4-7.6-2.1-13.9-2.4v-4.7c5.6,0.5,13.7,0.7,24.2,0.7c9.4,0,16.7-0.2,22-0.7
  v4.7C1478.2,309.7,1474.1,310.5,1471.5,311.8z"
                  />
                  <path
                    fill="currentColor"
                    d="M1547.8,311.3c-2.6,1-4.4,2.9-5.3,5.6c-1,2.7-1.5,7-1.5,13v117.6c0,5.9,0.5,10.3,1.5,13
  c1,2.7,2.7,4.6,5.3,5.6c2.6,1,6.7,1.6,12.4,1.9v4.7c-7.3-0.5-17.3-0.7-30.1-0.7c-13.6,0-23.7,0.2-30.3,0.7v-4.7
  c5.7-0.2,9.9-0.8,12.4-1.9c2.6-1,4.4-2.9,5.3-5.6c1-2.7,1.5-7,1.5-13V329.8c0-5.9-0.5-10.3-1.5-13c-1-2.7-2.7-4.6-5.3-5.6
  c-2.6-1-6.7-1.6-12.4-1.9v-4.7c6.6,0.5,16.7,0.7,30.3,0.7c12.8,0,22.8-0.2,30.1-0.7v4.7C1554.5,309.7,1550.4,310.3,1547.8,311.3z"
                  />
                  <path
                    fill="currentColor"
                    d="M1693.4,311.5c11.3,6.8,20.1,16.6,26.3,29.6c6.2,12.9,9.3,28.3,9.3,46.1c0,17.4-3.2,32.9-9.6,46.3
  c-6.4,13.4-15.3,23.8-26.7,31.2c-11.4,7.4-24.4,11.1-39,11.1c-15,0-28.2-3.4-39.5-10.2c-11.3-6.8-20.1-16.6-26.3-29.6
  c-6.2-12.9-9.3-28.3-9.3-46.1c0-17.4,3.2-32.9,9.6-46.3c6.4-13.4,15.3-23.8,26.7-31.2c11.4-7.4,24.4-11.1,39-11.1
  C1668.9,301.3,1682.1,304.7,1693.4,311.5z M1626.2,316.2c-7.7,7.1-13.7,16.9-17.8,29.5c-4.2,12.6-6.2,27.1-6.2,43.3
  c0,16.4,2.3,30.9,6.9,43.4c4.6,12.5,10.9,22.1,18.9,28.9c8,6.8,16.8,10.2,26.6,10.2c10.1,0,19-3.5,26.7-10.6
  c7.7-7.1,13.6-16.9,17.8-29.5c4.2-12.6,6.3-27.1,6.3-43.3c0-16.4-2.3-30.9-6.9-43.4c-4.6-12.5-10.9-22.1-18.9-28.9
  c-7.9-6.8-16.8-10.2-26.5-10.2C1642.9,305.6,1634,309.1,1626.2,316.2z"
                  />
                  <path
                    fill="currentColor"
                    d="M1884,311.8c-2.7,1.3-4.5,3.6-5.5,6.7c-1,3.1-1.5,7.7-1.5,13.8v141.4h-5.4L1772,319.8v125.1
  c0,6.1,0.5,10.7,1.6,13.8c1.1,3.1,3.1,5.3,6.1,6.7c3,1.4,7.6,2.1,13.8,2.4v4.7c-5.6-0.5-13.7-0.7-24.2-0.7c-9.4,0-16.7,0.2-22,0.7
  v-4.7c5.5-0.2,9.6-1,12.2-2.4c2.7-1.3,4.5-3.6,5.5-6.7c1-3.1,1.5-7.7,1.5-13.8V329.8c0-5.9-0.5-10.3-1.5-13
  c-1-2.7-2.7-4.6-5.3-5.6c-2.6-1-6.7-1.6-12.4-1.9v-4.7c5.2,0.5,12.5,0.7,22,0.7c7.1,0,13.3-0.2,18.5-0.7l83.7,128.9V332.2
  c0-6.1-0.5-10.7-1.6-13.8c-1-3.1-3.1-5.3-6.1-6.7c-3-1.4-7.6-2.1-13.9-2.4v-4.7c5.6,0.5,13.7,0.7,24.2,0.7c9.4,0,16.7-0.2,22-0.7
  v4.7C1890.7,309.7,1886.6,310.5,1884,311.8z"
                  />
                  <path
                    fill="currentColor"
                    d="M1935.4,456.2c6.8,9.9,17.2,14.9,31.4,14.9c5.4,0,10.4-1.1,15.1-3.4c4.7-2.2,8.4-5.7,11.3-10.5
  c2.9-4.7,4.3-10.7,4.3-17.8c0-6.1-1.5-11.6-4.5-16.4c-3-4.8-7-9.2-12.1-13.3c-5.1-4-11.9-8.6-20.4-13.7
  c-9.4-5.6-17-10.7-22.7-15.2c-5.7-4.5-10.4-9.8-14.1-16c-3.7-6.2-5.5-13.5-5.5-21.9c0-8.9,2-16.5,6.1-22.7
  c4.1-6.2,9.5-10.8,16.2-13.8c6.7-3,14.2-4.6,22.3-4.6c4.9,0,9,0.4,12.6,1.2c3.5,0.8,6.4,1.8,8.7,3c2.3,1.2,4.9,2.7,7.6,4.6
  c3.6,2.2,6.3,3.4,8.1,3.4c1.5,0,2.6-0.8,3.4-2.5c0.8-1.7,1.4-4.3,1.9-7.9h5.4c-0.8,9.3-1.2,25.4-1.2,48.4h-5.4
  c-0.6-8.1-2.4-15.6-5.3-22.4c-2.9-6.9-7.1-12.4-12.7-16.7c-5.6-4.3-12.6-6.4-20.9-6.4c-5.6,0-10.5,1.2-14.9,3.5
  c-4.4,2.3-7.8,5.6-10.3,9.7c-2.5,4.2-3.7,9-3.7,14.3c0,8.6,2.6,15.5,7.8,20.7c5.2,5.2,11.2,10,18.1,14.2c6.9,4.2,11.6,7,13.9,8.5
  c10,6.2,17.8,11.7,23.6,16.3c5.8,4.6,10.4,9.9,13.9,16c3.5,6.1,5.2,13.1,5.2,21.1c0,9.7-2.2,18-6.7,24.7
  c-4.5,6.7-10.5,11.8-18.1,15.2c-7.6,3.4-16.1,5.1-25.5,5.1c-7.6,0-13.8-0.8-18.6-2.4c-4.9-1.6-9.4-3.7-13.8-6.4
  c-3.5-2.2-6.2-3.4-8.1-3.4c-1.5,0-2.6,0.8-3.4,2.5c-0.8,1.7-1.4,4.3-1.9,7.9h-5.4c0.6-8.6,0.9-27.6,0.9-56.8h5.4
  C1924.7,433.3,1928.7,446.3,1935.4,456.2z"
                  />
                </g>
                <path
                  fill="#FCB42F"
                  d="M1182.1,129.2c-10.6-1.5-20.9-2.2-30.6-2.2c-13.8,0-26.5,1.5-38.5,4.5c4.9-16.7,6.5-35.5,4.8-56.8l-0.7-9
l-8.4,3.3c-20.1,8-36.4,17.9-49.3,29.8c-4.2-23.6-15.1-47.2-33-71.5l-5.3-7.3l-5.3,7.3c-17.9,24.4-28.8,48-33,71.5
C970,86.9,953.6,77,933.5,69l-8.4-3.3l-0.7,9c-1.6,21.3-0.1,40.1,4.8,56.8c-11.9-3-24.7-4.5-38.5-4.5c-9.6,0-19.9,0.7-30.6,2.2
l-8.9,1.2l3.8,8.2c16,34,36,57.8,61.2,72.8c22.1,13.1,48.4,19.5,80.6,19.5c0,0,0,0,0,0c7.7,0,15.6-0.3,24.2-1.1
c8.6,0.7,16.6,1.1,24.2,1.1c32.2,0,58.5-6.4,80.6-19.5c25.2-15,45.2-38.8,61.2-72.8l3.8-8.2L1182.1,129.2z M1105.2,85
c0.9,29-5.2,52.4-18.5,71.4c-10.3,14.6-25,27-45.8,38.2c12.6-23.7,19.2-45.3,20.4-66.7c0.2-4,0.3-8,0.1-12
C1072.2,103.6,1086.7,93.3,1105.2,85z M1021.2,42.7c19.6,29,28.4,56.7,26.9,84.4c-1.3,23.4-9.9,47.5-26.9,75.1
c-17-27.6-25.6-51.7-26.9-75.1C992.8,99.4,1001.6,71.7,1021.2,42.7z M937.2,85c18.5,8.4,33,18.6,43.8,31c-0.2,4-0.1,8,0.1,12
c1.2,21.4,7.8,43,20.4,66.7c-20.8-11.3-35.5-23.6-45.8-38.3C942.4,137.4,936.3,114,937.2,85z M996.9,217.6
c-29.7,0-53.8-5.8-73.8-17.6c-20.8-12.4-37.8-31.6-51.9-58.8c6.8-0.7,13.3-1,19.5-1c16.2,0,30.9,2.2,44.3,6.7c2.8,6,6,11.7,9.8,17
c13.8,19.6,34.3,35.3,64.5,49.4c0.8,1.3,1.5,2.5,2.3,3.8C1006.5,217.5,1001.7,217.6,996.9,217.6L996.9,217.6z M1119.2,200
c-19.9,11.9-44.1,17.6-73.8,17.6c-4.7,0-9.6-0.1-14.6-0.4c0.7-1.3,1.5-2.6,2.3-3.8c30.2-14.1,50.7-29.8,64.5-49.4
c3.8-5.4,7-11,9.8-17c13.4-4.5,28.1-6.7,44.3-6.7c6.2,0,12.8,0.3,19.5,1C1157,168.3,1140,187.6,1119.2,200z"
                />
              </g>
            </svg>
          </StyledLogoIcon>
        )
    }
  }

  return (
    <StyledLogoWrapper logoType={logoType} dark={dark}>
      <StyledLogo to="/">
        <StyledLogoLabel>
          Top Companions - Luxury Escort Service
        </StyledLogoLabel>
        {renderLogo()}
      </StyledLogo>
    </StyledLogoWrapper>
  )
}

export default Logo

Logo.defaultProps = {
  dark: false,
  logoType: LOGO_TYPES.default,
}

Logo.propTypes = {
  dark: PropTypes.bool,
  logoType: PropTypes.oneOf(Object.keys(LOGO_TYPES)),
}
