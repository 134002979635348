import theme from '../themes/theme'

const [phone, tabletSmall, tablet, desktop] = theme.breakpoints

const media = {
  phone: `(min-width: ${phone})`,
  tabletSmall: `(min-width: ${tabletSmall})`,
  tablet: `(min-width: ${tablet})`,
  desktop: `(min-width: ${desktop})`,
}

export default media

export const customMedia = {
  inner: `(min-width: ${theme.sizes.inner})`,
  maxPhone: `(max-width: ${parseInt(phone) - 1}px)`,
  maxTabletSmall: `(max-width: ${parseInt(tabletSmall) - 1}px)`,
  maxTablet: `(max-width: ${parseInt(tablet) - 1}px)`,
}
