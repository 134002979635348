import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledFooter,
  StyledFooterTop,
  StyledFooterBottom,
  StyledFooterTopContainer,
  StyledFooterTopContainerCol,
  StyledFooterBottomContainer,
  StyledFooterBottomContainerCol,
} from './styles'
import FooterItem from '../FooterItem/FooterItem'
import Logo from '../Logo/Logo'
import Copyrights from '../Copyrights/Copyrights'
import Inner from '../Inner'
import { LOGO_TYPES } from '../../constants/logoTypes'

const Footer = ({ pages, links, locations }) => (
  <StyledFooter>
    <StyledFooterTop bg="lightGrey">
      <Inner>
        <div className="uiAnimBottom">
          <StyledFooterTopContainer>
            <StyledFooterTopContainerCol pages>
              <FooterItem footerTitle={pages.title} footerList={pages.list} />
            </StyledFooterTopContainerCol>
            <StyledFooterTopContainerCol links>
              <FooterItem footerTitle={links.title} footerList={links.list} />
            </StyledFooterTopContainerCol>
            <StyledFooterTopContainerCol locations>
              <FooterItem
                isInColumns
                footerTitle={locations.title}
                footerList={locations.list}
              />
            </StyledFooterTopContainerCol>
          </StyledFooterTopContainer>
        </div>
      </Inner>
    </StyledFooterTop>
    <StyledFooterBottom>
      <Inner>
        <div className="uiAnimBottom">
          <StyledFooterBottomContainer>
            <StyledFooterBottomContainerCol>
              <Logo dark logoType={LOGO_TYPES.compact} />
            </StyledFooterBottomContainerCol>
            <StyledFooterBottomContainerCol justifyContent="flex-end">
              <Copyrights />
            </StyledFooterBottomContainerCol>
          </StyledFooterBottomContainer>
        </div>
      </Inner>
    </StyledFooterBottom>
  </StyledFooter>
)

export default Footer

Footer.propTypes = {
  pages: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  locations: PropTypes.object.isRequired,
}
