import { css } from 'styled-components'

import { transition } from '../../common/mixins'
import media, { customMedia } from '../../common/MediaQueries'

export const buttonBase = css`
  position: relative;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 12rem;
  padding: 0.8125rem 0.75rem;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  ${transition}
  cursor: pointer;

  span {
    position: relative;
    z-index: 1;
  }

  @media ${customMedia.maxPhone} {
    min-width: 13rem;
  }

  @media ${media.phone} {
    padding: 0.8125rem 2.25rem;
  }

  ${({ decor }) =>
    decor &&
    css`
      margin-right: 0.3125rem;
      margin-bottom: 0.375rem;

      &::before,
      &::after {
        pointer-events: none;
        position: absolute;
        content: '';
        transition: border-color 250ms, top 250ms, left 250ms, right 250ms,
          bottom 250ms;
      }

      &::before {
        border-style: solid;
        border-width: 1px;
        top: 0.375rem;
        right: -0.3125rem;
        bottom: -0.375rem;
        left: 0.3125rem;
      }

      &::after {
        background: inherit;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &:hover {
        &::before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-color: transparent;
        }
      }
    `}
`

export const primary = css`
  background: ${({ theme }) => theme.colors.yellow};
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0.25px;

  &::before {
    border-color: ${({ theme }) => theme.colors.yellow};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.black};
  }
`

export const secondary = css`
  background: ${({ theme }) => theme.colors.yellow};
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  letter-spacing: 0.25px;

  &::before {
    border-color: ${({ theme }) => theme.colors.yellow};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.yellow};
  }
`

export const inverted = css`
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.yellow};
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  letter-spacing: 0.25px;

  &::before {
    border-color: ${({ theme }) => theme.colors.yellow};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.black};
  }
`
export const dark = css`
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0.25px;

  &::before {
    border-color: ${({ theme }) => theme.colors.black};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.yellow};

    &::before {
      border-color: ${({ theme }) => theme.colors.yellow};
    }
  }
`

export const light = css`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.yellow};
  letter-spacing: 0.25px;

  &::before {
    border-color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};

    &::before {
      border-color: ${({ theme }) => theme.colors.black};
    }
  }
`

export const simple = css`
  width: 100%;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  &:hover {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }
`

export const small = css`
  min-height: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

export const medium = css`
  min-height: 3.125rem;
`
