import { createContext } from 'react'

const AuthorizationContext = createContext({
  isLoggedIn: false,
  login: (data: any) => Promise.resolve(null),
  logout: () => null,
  refresh: () => Promise.resolve(),
  isChecked: false,
  error: '',
})

export default AuthorizationContext
