import styled from 'styled-components'
import Link from 'gatsby-link'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

const StyledSubMenu = styled.ul`
  padding: 0.25rem 0 0.25rem 2.5rem;
  z-index: 2;

  @media ${media.tablet} {
    width: 11.875rem;
    padding: 0.25rem 1.25rem;
    background: ${({ theme }) => rgba(theme.colors.white, 0.1)};
    border: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.5)};
    ${transition}
  }
`

const StyledSubMenuItem = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.1)};
  }

  @media ${media.tablet} {
    &:not(:last-child) {
      border-bottom-color: ${({ theme }) => rgba(theme.colors.white, 0.5)};
    }
  }
`

const StyledSubMenuLink = styled(Link)`
  display: block;
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  padding: 0.875rem 0.125rem;
  ${transition}

  &:hover {
    color: ${({ theme }) => theme.colors.yellow};
  }
`

export { StyledSubMenu, StyledSubMenuItem, StyledSubMenuLink }
