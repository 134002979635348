import styled, { css } from 'styled-components'

import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

const StyledHamburgerLine = styled.span`
  position: relative;
  display: flex;
  flex-shrink: 0;
  height: 0.0625rem;
  background: transparent;
  color: currentColor;

  &::before,
  &::after {
    display: block;
    content: '';
    right: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    width: 100%;
    height: 0.0625rem;
    background: currentColor;
    ${transition}
  }

  &::before {
    top: 0.25rem;
  }

  &::after {
    bottom: 0.25rem;
  }
`

const StyledHamburger = styled.button`
  position: relative;
  width: 1.3125rem;
  height: 1.3125rem;
  z-index: 100;
  transform: translateZ(0);
  cursor: pointer;

  ${({ isOpened }) =>
    isOpened &&
    css`
      ${StyledHamburgerLine} {
        background-color: transparent;
        transform-origin: 50% 50%;

        &::before,
        &::after {
          background: ${({ theme }) => theme.colors.white};
        }
      }

      ${StyledHamburgerLine} {
        &::before {
          top: 0;
          transform: rotate3d(0, 0, 1, -45deg);
        }
      }

      ${StyledHamburgerLine} {
        &::after {
          bottom: 0;
          transform: rotate3d(0, 0, 1, 45deg);
        }
      }
    `}

    @media ${media.tablet} {
      display: none;
    }
`

export { StyledHamburger, StyledHamburgerLine }
