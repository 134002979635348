import { URL_PATHS } from './urlPaths'

export default [
  {
    label: 'About Us',
    to: URL_PATHS.ABOUT,
  },
  {
    label: 'Models',
    to: URL_PATHS.MODELS,
  },
  {
    label: 'Travel Escort',
    to: URL_PATHS.TRAVEL_ESCORT,
  },
  {
    label: 'Casting',
    to: URL_PATHS.CASTING,
  },
  {
    label: 'Bookings',
    to: URL_PATHS.BOOKINGS,
    subMenu: [
      {
        label: 'Booking Form',
        to: URL_PATHS.BOOKINGS,
      },
      {
        label: 'Rates',
        to: URL_PATHS.RATES,
      },
      {
        label: `Gentlemen's Guide`,
        to: URL_PATHS.GENTLEMENS_GUIDE,
      },
      {
        label: 'Services',
        to: URL_PATHS.SERVICES,
      },
    ],
  },
  {
    label: 'News',
    to: URL_PATHS.NEWS,
  },
  {
    label: 'Contact',
    to: URL_PATHS.CONTACT,
  },
]
